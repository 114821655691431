import React from 'react';
import Tooltip from 'react-native-walkthrough-tooltip';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Text, Dimensions, View, StyleSheet} from 'react-native';
import {currentWalkthrough, isCurrentLast} from './walkthrough';
import {endWalkthrough} from './actions/PlayerActions';
import {progressWalkthrough} from './actions/GameStateActions';
import isWeb from './isWeb';
import {registerChange, scaleMin} from './scaleUtils';
import log from './log';

class WalkthroughTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.timeoutId = null;
    this.showingTip = false;
  }

  componentDidMount() {
    registerChange(this);
  }

  componentWillUnmount() {
    registerChange(this, true);
  }

  cancelTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  startTimer(timeout) {
    setTimeout(() => {
      this.progressWalkthrough();
      this.cancelTimeout();
    }, timeout);
  }

  progressWalkthrough() {
    if (this.showingTip) {
      this.showingTip = false;
      if (isCurrentLast(this.props.walkthrough)) {
        this.props.endWalkthrough();
      } else {
        this.props.progressWalkthrough();
      }
    }
  }

  handleClick(event) {
    log.debug('clickEvent', event && event.nativeEvent);
    if (!event) {
      this.cancelTimeout();
      this.progressWalkthrough();
    } else if (event.nativeEvent && event.nativeEvent.pageY) {
      const dimensions = Dimensions.get('window');
      if (event.nativeEvent.pageY > dimensions.height * 0.93) {
        this.cancelTimeout();
        this.props.endWalkthrough();
      }
    }
  }

  renderTip(walkthrough) {
    let containerStyle = null;
    if (isWeb) {
      let containerSize = 70;
      if (walkthrough.tip.length > 35) {
        containerSize = 300;
      } else if (walkthrough.tip.length > 8) {
        containerSize = 180;
      }
      containerStyle = {width: scaleMin(containerSize, this.state)};
    }
    return (
      <View style={[styles.tipContainer, containerStyle]}>
        <Text style={[styles.tipText, {fontSize: scaleMin(22, this.state)}]}>
          {walkthrough.tip}
        </Text>
      </View>
    );
  }

  render() {
    const walkthrough = currentWalkthrough(this.props.walkthrough);
    // log.debug('walkthrough.wid', walkthrough && walkthrough.wid, 'this.props.wid', this.props.wid);
    if (
      walkthrough &&
      walkthrough.wid === this.props.wid &&
      !this.props.isSolveGame
    ) {
      // log.debug('rendering walkthrough', this.props.wid);
      if (!this.showingTip) {
        this.showingTip = true;
      }
      if (walkthrough.timeout && !this.timeoutId) {
        this.startTimer(walkthrough.timeout);
      }
      const children = React.Children.map(this.props.children, (child) => {
        const originalOnPress = child.props.onPress;
        if (!originalOnPress) {
          return child;
        }
        return React.cloneElement(child, {
          onPress: (...args) => {
            this.handleClick();
            originalOnPress(...args);
          },
        });
      });
      return (
        <Tooltip
          isVisible={true}
          content={this.renderTip(walkthrough)}
          onClose={this.handleClick.bind(this)}
          closeOnContentInteraction={false}
          closeOnChildInteraction={false}
          placement={walkthrough.placement || 'top'}>
          {children}
        </Tooltip>
      );
    }
    return this.props.children;
  }
}

const styles = StyleSheet.create({
  tipContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  tipContainerLarge: {
    width: 300,
  },
  tipContainerSmall: {
    width: 60,
  },
  tipContainerMedium: {
    width: 180,
  },
  tipText: {
    textAlign: 'center',
  },
});

const mapStateToProps = (state) => {
  const {gameState, player} = state;
  return {
    walkthrough: gameState.walkthrough,
    isSolveGame: player.isSolveGame,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      progressWalkthrough,
      endWalkthrough,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(WalkthroughTooltip);
