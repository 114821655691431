const INITIAL_STATE = {
  screen: 'GAME',
  slideOutScreens: [],
  transitioningId: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SCREEN_SELECTED': {
      if (action.payload.screen === state.screen) {
        return state;
      }
      const slideOutScreens = [
        {isTransitionIn: false, screen: state.screen},
        {isTransitionIn: true, screen: action.payload.screen},
      ].filter((slideOutScreen) => slideOutScreen.screen !== 'GAME');
      return {
        ...state,
        screen: action.payload.screen,
        slideOutScreens,
        transitioningId: action.payload.transitioningId,
      };
    }
    case 'SCREEN_ANIMATION_COMPLETE':
      if (action.payload.transitioningId !== state.transitioningId) {
        return state;
      }
      return {
        ...state,
        slideOutScreens: state.slideOutScreens.filter(
          (slideOutScreen) => slideOutScreen.isTransitionIn,
        ),
        transitioningId: null,
      };
    case 'START_SOLVE_GAME':
      return {
        ...state,
        screen: 'GAME',
        slideOutScreens: [],
      };
    default:
      return state;
  }
};
