import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Text, Modal, View, StyleSheet, Button} from 'react-native';
import {currentWalkthrough, isCurrentLast} from './walkthrough';
import {endWalkthrough} from './actions/PlayerActions';
import {progressWalkthrough} from './actions/GameStateActions';
import {registerChange, scaleMin} from './scaleUtils';

class WalkthroughBanner extends React.Component {
  constructor(props) {
    super(props);
    this.timeoutId = null;
  }

  componentDidMount() {
    registerChange(this);
  }

  componentWillUnmount() {
    registerChange(this, true);
  }

  cancelTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  startTimer(timeout) {
    setTimeout(() => {
      this.progressWalkthrough();
      this.cancelTimeout();
    }, timeout);
  }

  progressWalkthrough() {
    if (isCurrentLast(this.props.walkthrough)) {
      this.props.endWalkthrough();
    } else {
      this.props.progressWalkthrough();
    }
  }

  handleClick(button) {
    this.cancelTimeout();
    this.progressWalkthrough();
  }

  renderButton(buttonName) {
    let title;
    let action;
    let colour;

    switch (buttonName) {
      case 'START':
        title = 'Start a walkthrough of the game';
        action = () => this.handleClick();
        break;
      case 'SKIP':
        title = 'Skip';
        action = () => this.props.endWalkthrough();
        colour = '#999999';
        break;
      case 'END':
        title = 'Start playing';
        action = () => this.props.endWalkthrough();
        break;
    }
    return (
      <View key={buttonName} style={{padding: scaleMin(5, this.state)}}>
        <Button title={title} onPress={action} color={colour} />
      </View>
    );
  }

  render() {
    if (this.props.isSolveGame) {
      return null;
    }
    const walkthrough = currentWalkthrough(this.props.walkthrough);
    if (!walkthrough || !walkthrough.bannerTitle) {
      // This would need more logic if there are two banners in a row with a timeout
      this.cancelTimeout();
      return null;
    }
    if (walkthrough.timeout && !this.timeoutId) {
      this.startTimer(walkthrough.timeout);
    }
    return (
      <Modal transparent style={styles.modal}>
        <View style={styles.modalWrapper}>
          <View style={styles.modalContent}>
            <Text
              style={[
                styles.bannerTitle,
                {
                  fontSize: scaleMin(30, this.state),
                  paddingVertical: scaleMin(10, this.state),
                },
              ]}>
              {walkthrough.bannerTitle}
            </Text>
            {walkthrough.text ? (
              <Text
                style={[
                  styles.text,
                  {
                    fontSize: scaleMin(22, this.state),
                    paddingVertical: scaleMin(10, this.state),
                  },
                ]}>
                {walkthrough.text}
              </Text>
            ) : null}
            {walkthrough.buttons.map((button) => this.renderButton(button))}
          </View>
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  modal: {
    width: '100%',
    height: '100%',
  },
  modalWrapper: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#00000080',
  },
  modalContent: {
    width: '80%',
    maxWidth: 400,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bannerTitle: {
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 'bold',
  },
  text: {
    textAlign: 'center',
  },
});

const mapStateToProps = (state) => {
  const {gameState, player} = state;
  return {
    walkthrough: gameState.walkthrough,
    isSolveGame: player.isSolveGame,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      progressWalkthrough,
      endWalkthrough,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(WalkthroughBanner);
