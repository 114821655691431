import durations from './gameDurations';
import levels from './difficultyLevels';

const answerMap = [];
for (let answerLevel = 0; ; ++answerLevel) {
  const allowedDurations = {};
  let minDuration;
  durations.forEach((duration, index) => {
    const minOffset = Math.max(answerLevel - (durations.length - 1 - index), 0);
    if (minOffset < levels.length) {
      allowedDurations[duration.duration] = levels[minOffset].level;
      minDuration = duration.duration;
    }
  });
  if (Object.keys(allowedDurations).length === 0) {
    break;
  }
  answerMap.unshift({
    answers: answerLevel * 5,
    minDuration,
    durations: allowedDurations,
  });
}

function getAnswerMap(correctAnswers) {
  return answerMap.find(
    (answerLevelDef) => correctAnswers >= answerLevelDef.answers,
  );
}

export function getLevelDurations(correctAnswers) {
  const match = getAnswerMap(correctAnswers);
  const levelDurations = {};
  levels.forEach((level) => {
    levelDurations[level.level] = [...durations]
      .reverse()
      .find(
        (duration) =>
          match.durations[duration.duration] &&
          match.durations[duration.duration] <= level.level,
      );
  });
  return {levelDurations, answerLevel: match.answers};
}

export function isCombinationEnabled(level, gameDuration, correctAnswers) {
  const match = getAnswerMap(correctAnswers);
  return match.durations[gameDuration] <= level;
}

export function getMinCombination(level, gameDuration, correctAnswers) {
  const match = getAnswerMap(correctAnswers);
  let minDuration = gameDuration;
  if (!match.durations[minDuration]) {
    minDuration = match.minDuration;
  }
  let minLevel = level;
  if (minLevel < match.durations[minDuration]) {
    minLevel = match.durations[minDuration];
  }
  return {minLevel, minDuration};
}
