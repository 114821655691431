import {v4 as uuidv4} from 'react-native-uuid';
import {gameWon} from './PlayerActions';
import {getRandomGame} from '../GameUtils';
import {queuePushOperation} from '../syncPushApi';
import GameNumber from '../GameNumber';
import {play} from '../sounds';

const checkAnswer = (dispatch, getState) => {
  const state =
    getState().gameState.solveGame ||
    getState().gameState.walkthrough ||
    getState().gameState;
  if (state.currentNumbers.length === 1) {
    const isCorrectAnswer =
      state.currentNumbers[0].value === state.target.toString();
    if (isCorrectAnswer) {
      const elapsed = Date.now() - state.gameStart;
      dispatch(
        gameWon(
          state.level,
          state.gameDuration,
          state.currentNumbers[0],
          elapsed,
          false,
        ),
      );
    } else {
      play('WRONG_ANSWER');
    }
  }
};

export const reset = (didLevelUp) => ({
  type: 'RESET',
  payload: {didLevelUp},
});

export const selectNumber = (index) => (dispatch, getState) => {
  play('CLICK');
  dispatch(applySelectNumber(index));
  checkAnswer(dispatch, getState);
};

const applySelectNumber = (index) => ({
  type: 'SELECT_NUMBER',
  payload: index,
});

export const selectOperator = (operator) => (dispatch, getState) => {
  play('CLICK');
  dispatch(applySelectOperator(operator));
  checkAnswer(dispatch, getState);
};

export const applySelectOperator = (operator) => ({
  type: 'SELECT_OPERATOR',
  payload: operator,
});

const applyUndo = () => ({
  type: 'UNDO',
});

export const undo = () => (dispatch) => {
  play('UNDO');
  dispatch(applyUndo());
};

const applyUseHint = () => ({
  type: 'USE_HINT',
  payload: {
    now: Date.now(),
  },
});

export const useHint = () => (dispatch, getState) => {
  const beforeState = getState();
  play('CLICK');
  dispatch(applyUseHint());
  const afterState = getState();
  if (
    !beforeState.player.isAnonymous &&
    !beforeState.gameState.walkthrough &&
    afterState.player.items.hints.used > beforeState.player.items.hints.used
  ) {
    const input = {
      numberOfHintsUsed:
        afterState.player.items.hints.used -
        beforeState.player.items.hints.used,
    };
    const event = {
      id: 'ID',
      eventType: 'HINT_USED',
      level: afterState.gameState.level,
      gameDuration: afterState.gameState.gameDuration,
      answer: afterState.gameState.target,
      numbers: beforeState.gameState.currentNumbers
        .map((n) => n.value)
        .join(','),
      gameID: beforeState.player.gameID,
      playerUpdate: JSON.stringify(input),
    };
    queuePushOperation('updatePlayer', {event, ...input});
  }
};

export const applyGiveUp = (level, gameDuration, elapsed) => ({
  type: 'GIVE_UP',
  payload: {
    level,
    gameDuration,
    elapsed,
    now: Date.now(),
  },
});

const applyShowGiveUpConfirm = () => ({
  type: 'SHOW_GIVE_UP_CONFIRM',
});

export const showGiveUpConfirm = () => (dispatch) => {
  play('CLICK');
  dispatch(applyShowGiveUpConfirm());
};

export const cancelGiveUpConfirm = () => ({
  type: 'CANCEL_GIVE_UP_CONFIRM',
});

export const giveUp = () => (dispatch, getState) => {
  play('LOOSE');
  const beforeState = getState();
  const state = beforeState.gameState;
  const elapsed = Date.now() - state.gameStart;
  const afterState = getState();
  dispatch(applyGiveUp(state.level, state.gameDuration, elapsed));
  if (!beforeState.player.isAnonymous && !beforeState.gameState.walkthrough) {
    const event = {
      id: 'ID',
      eventType: 'GIVE_UP',
      level: afterState.gameState.level,
      gameDuration: afterState.gameState.gameDuration,
      answer: afterState.gameState.target,
      numbers: beforeState.gameState.currentNumbers
        .map((n) => n.value)
        .join(','),
      gameID: beforeState.player.gameID,
    };
    queuePushOperation('updatePlayer', {event});
  }
};

export const showAnswer = () => (dispatch, getState) => {
  const state = getState().gameState.walkthrough || getState().gameState;
  const elapsed = Date.now() - state.gameStart;
  dispatch(
    gameWon(state.level, state.gameDuration, {equation: ''}, elapsed, true),
  );
};

const applyNewGame = (gameData, newGameRequired) => ({
  type: 'NEW_GAME',
  payload: {
    gameData,
    newGameRequired,
    gameID: newGameRequired ? uuidv4() : null,
    now: Date.now(),
  },
});

const walkthroughGame = {
  target: 30,
  numbers: [2, 1, 3, 9, 4].map((number) => new GameNumber(number)),
  sampleResult: '(((4 * 9) - (2 * 3)) * 1)',
  hints: [
    {
      value1: '4',
      value2: '9',
      operator: '*',
    },
    {
      value1: '2',
      value2: '3',
      operator: '*',
    },
    {
      value1: '36',
      value2: '6',
      operator: '-',
    },
  ],
};

export const newGame = (newGameRequired) => async (dispatch, getState) => {
  const beforeState = getState();
  const gameData =
    beforeState.gameState.walkthrough &&
    beforeState.gameState.walkthrough.currentStep < 8
      ? walkthroughGame
      : await getRandomGame(beforeState.gameState.level);
  play('CLICK');
  dispatch(applyNewGame(gameData, newGameRequired));
  if (!beforeState.player.isAnonymous && !beforeState.gameState.walkthrough) {
    const afterState = getState();
    const input = {};
    if (
      afterState.player.items.lives.used > beforeState.player.items.lives.used
    ) {
      input.numberOfLivesUsed =
        afterState.player.items.lives.used -
        beforeState.player.items.lives.used;
    }
    const event = {
      id: 'ID',
      eventType: 'NEW_GAME',
      level: afterState.gameState.level,
      gameDuration: afterState.gameState.gameDuration,
      answer: afterState.gameState.target,
      numbers: afterState.gameState.currentNumbers
        .map((n) => n.value)
        .join(','),
      gameID: beforeState.player.gameID,
      playerUpdate: JSON.stringify(input),
    };
    queuePushOperation('updatePlayer', {event, ...input});
  }
};

const applyTimeUp = () => ({
  type: 'TIME_UP',
  payload: {
    now: Date.now(),
  },
});

export const timeUp = () => (dispatch, getState) => {
  play('LOOSE');
  const beforeState = getState();
  dispatch(applyTimeUp());
  const afterState = getState();
  if (!beforeState.player.isAnonymous && !beforeState.gameState.walkthrough) {
    const event = {
      id: 'ID',
      eventType: 'TIME_UP',
      level: afterState.gameState.level,
      gameDuration: afterState.gameState.gameDuration,
      answer: afterState.gameState.target,
      numbers: beforeState.gameState.currentNumbers
        .map((n) => n.value)
        .join(','),
      gameID: beforeState.player.gameID,
    };
    queuePushOperation('updatePlayer', {event});
  }
};

const applySetLevel = (level) => ({
  type: 'SET_LEVEL',
  payload: level,
});

export const setLevel = (level, interactive = false) => (dispatch) => {
  if (interactive) {
    play('CLICK');
  }
  dispatch(applySetLevel(level));
};

const applySetGameDuration = (duration) => ({
  type: 'SET_GAME_DURATION',
  payload: duration,
});

export const setGameDuration = (duration, interactive = false) => (
  dispatch,
) => {
  if (interactive) {
    play('CLICK');
  }
  dispatch(applySetGameDuration(duration));
};

export const showDifficultyRestrictions = () => ({
  type: 'SHOW_DIFFICULTY_RESTRICTIONS',
});

export const clearShowDifficultyRestrictions = () => ({
  type: 'CLEAR_DIFFICULTY_RESTRICTIONS',
});

const applyProgressWalkthrough = () => ({
  type: 'PROGRESS_WALKTHROUGH',
});

const clearProgressWalkthrough = () => ({
  type: 'CLEAR_PROGRESS_WALKTHROUGH',
});

const enableWalkthrough = () => ({
  type: 'ENABLE_WALKTHROUGH',
});

export const progressWalkthrough = () => (dispatch) => {
  dispatch(clearProgressWalkthrough());
  setTimeout(() => dispatch(applyProgressWalkthrough()), 10);
};

export const refreshWalkthrough = () => (dispatch) => {
  setTimeout(() => dispatch(clearProgressWalkthrough()), 10);
  setTimeout(() => dispatch(enableWalkthrough()), 10);
};
