import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Modal,
  Button,
  Share,
  Platform,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  selectNumber,
  selectOperator,
  undo,
  useHint,
  showGiveUpConfirm,
  cancelGiveUpConfirm,
  giveUp,
  showAnswer,
  setLevel,
  setGameDuration,
  newGame,
  timeUp,
  reset,
  showDifficultyRestrictions,
  clearShowDifficultyRestrictions,
} from './actions/GameStateActions';
import {selectScreen} from './actions/NavActions';
import {endSolveGame, resetGame} from './actions/PlayerActions';
import NumberButton from './NumberButton';
import OperatorButton from './OperatorButton';
import ActionButton from './ActionButton';
import Equation from './Equation';
import UndoButton from './UndoButton';
import GameTimer from './GameTimer';
import DifficultyLevelButtons from './DifficultyLevelButtons';
import GameDurationButtons from './GameDurationButtons';
import AnimatedMessage from './AnimatedMessage';
import Loading from './Loading';
import Target from './icons/Target';
import {registerChange, scaleW} from './scaleUtils';
import {isCombinationEnabled, getMinCombination} from './levelUtils';
import {equationToString} from './equationUtils';
import WalkthroughTooltip from './WalkthroughTooltip';
import log from './log';
import DifficultyRestrictions from './DifficultyRestrictions';

const operators = ['+', '-', '*', '/', '^'];

const canShare = Platform.select({
  web: () =>
    window.navigator.canShare
      ? window.navigator.canShare({text: 'test'})
      : window.navigator.share,
  default: () => true,
});

class Game extends React.Component {
  componentDidMount() {
    this.props.reset(this.props.clearedLevelUp);
    registerChange(this);
    this.checkCombination();
  }

  componentWillUnmount() {
    registerChange(this, true);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.correctAnswers !== this.props.correctAnswers ||
      prevProps.level !== this.props.level ||
      prevProps.gameDuration !== this.props.gameDuration
    ) {
      this.checkCombination();
    }
  }

  checkCombination() {
    if (
      !isCombinationEnabled(
        this.props.level,
        this.props.gameDuration,
        this.props.correctAnswers,
      )
    ) {
      const {minLevel, minDuration} = getMinCombination(
        this.props.level,
        this.props.gameDuration,
        this.props.correctAnswers,
      );
      if (minLevel !== this.props.level) {
        this.props.setLevel(minLevel);
      }
      if (minDuration !== this.props.gameDuration) {
        this.props.setGameDuration(minDuration);
      }
    }
  }

  renderUndo() {
    if (this.props.stack.length === 0) {
      return null;
    }
    return (
      <View style={styles.undoArea}>
        <UndoButton onPress={() => this.props.undo()} />
      </View>
    );
  }

  renderTimeout() {
    if (typeof this.props.gameDuration === 'undefined') {
      return null;
    }
    return (
      <View style={styles.timerArea}>
        <GameTimer
          gameStart={this.props.gameStart}
          gameDuration={this.props.gameDuration}
          timeUp={() => this.props.timeUp()}
        />
      </View>
    );
  }

  renderTarget() {
    return (
      <View style={styles.targetArea}>
        <View style={styles.targetWrapper}>
          <View style={styles.targetBackgroundWrapper}>
            <Target />
          </View>
          <Text style={styles.targetText}>{this.props.target}</Text>
        </View>
        {this.renderTimeout()}
        {this.renderUndo()}
      </View>
    );
  }

  renderAnswer() {
    const buttonIndex1 =
      typeof this.props.selectedIndex1 === 'undefined'
        ? this.props.softSelectedIndex1
        : this.props.selectedIndex1;
    if (
      typeof buttonIndex1 === 'undefined' &&
      typeof this.props.selectedIndex2 === 'undefined' &&
      !this.props.selectedOperator
    ) {
      return (
        <View style={styles.answerArea}>
          <Text
            style={[styles.answerInfoText, {fontSize: scaleW(25, this.state)}]}>
            Select two numbers and an operator
          </Text>
        </View>
      );
    }
    if (
      typeof this.props.softSelectedIndex1 !== 'undefined' &&
      typeof this.props.selectedIndex2 === 'undefined' &&
      !this.props.selectedOperator &&
      this.props.currentNumbers[this.props.softSelectedIndex1].value ===
        `${this.props.target}`
    ) {
      return (
        <View style={styles.answerArea}>
          <Text
            style={[styles.answerInfoText, {fontSize: scaleW(20, this.state)}]}>
            All numbers need to be used
          </Text>
        </View>
      );
    }
    let answer = '';
    if (typeof buttonIndex1 === 'undefined') {
      answer += '▢';
    } else {
      answer += this.props.currentNumbers[buttonIndex1].value;
    }
    answer += ` ${this.props.selectedOperator || '▢'} `;
    if (typeof this.props.selectedIndex2 === 'undefined') {
      answer += '▢';
    } else {
      answer += this.props.currentNumbers[this.props.selectedIndex2].value;
    }
    return (
      <View style={styles.answerArea}>
        <Equation equation={answer} isLarger={true} />
      </View>
    );
  }

  renderActiveGame() {
    log.debug('renderActiveGame this.props', this.props);
    return (
      <View style={styles.container}>
        {this.renderTarget()}
        {this.renderAnswer()}
        <View style={styles.selectionArea}>
          <View style={styles.selectionPanel}>
            {this.props.currentNumbers.map((number, index) => (
              <WalkthroughTooltip wid={`number-${index}`} key={index}>
                <NumberButton
                  value={number.value}
                  equation={number.equation}
                  isSelected={
                    index === this.props.selectedIndex1 ||
                    index === this.props.selectedIndex2
                  }
                  isSoftSelected={index === this.props.softSelectedIndex1}
                  onPress={() => this.props.selectNumber(index)}
                />
              </WalkthroughTooltip>
            ))}
          </View>
          <View style={styles.selectionPanel}>
            {operators.map((operator) => (
              <OperatorButton
                key={operator}
                operator={operator}
                isSelected={operator === this.props.selectedOperator}
                onPress={() => this.props.selectOperator(operator)}
              />
            ))}
          </View>
        </View>
        <View style={styles.giveUpArea}>
          {this.props.isSolveGame && !this.props.sampleResult ? null : (
            <WalkthroughTooltip wid="answer">
              <ActionButton
                text="Show Answer"
                type="SQUAT"
                item={!this.props.isSolveGame && this.props.items.answers}
                onPress={() => this.props.showAnswer()}
                onPressItemEmpty={() => this.props.selectScreen('STORE')}
              />
            </WalkthroughTooltip>
          )}
          {this.props.hints.length > 0 ? (
            <WalkthroughTooltip wid="hint">
              <ActionButton
                text="Give Me A Hint"
                type="SQUAT"
                item={!this.props.isSolveGame && this.props.items.hints}
                onPress={() => this.props.useHint()}
                onPressItemEmpty={() => this.props.selectScreen('STORE')}
              />
            </WalkthroughTooltip>
          ) : undefined}
          <ActionButton
            text="Give Up"
            type="SQUAT"
            onPress={() => this.props.showGiveUpConfirm()}
          />
        </View>
      </View>
    );
  }

  renderNewGame(newGameRequired = false) {
    if (this.props.isSolveGame) {
      return (
        <View style={styles.newGameArea}>
          <ActionButton
            text="Try Again"
            onPress={() => this.props.resetGame()}
          />
          <ActionButton
            text="Continue with Equationate"
            onPress={() => this.props.endSolveGame()}
          />
        </View>
      );
    }
    return (
      <View style={styles.newGameArea}>
        <DifficultyLevelButtons
          setLevel={(level) => this.props.setLevel(level, true)}
          showRestrictions={() => this.props.showDifficultyRestrictions()}
          level={this.props.level}
          gameDuration={this.props.gameDuration}
          correctAnswers={this.props.correctAnswers}
        />
        <GameDurationButtons
          setGameDuration={(duration) =>
            this.props.setGameDuration(duration, true)
          }
          showRestrictions={() => this.props.showDifficultyRestrictions()}
          level={this.props.level}
          gameDuration={this.props.gameDuration}
          correctAnswers={this.props.correctAnswers}
        />
        {this.props.correctAnswers > 0 && !newGameRequired ? (
          <Text style={styles.correctAnswers}>
            {this.props.correctAnswers} Correct
          </Text>
        ) : null}
        {newGameRequired ? (
          <WalkthroughTooltip wid="new-game">
            <ActionButton
              text="New Game"
              item={this.props.items.lives}
              onPress={() => this.props.newGame(true)}
              onPressItemEmpty={() => this.props.selectScreen('STORE')}
            />
          </WalkthroughTooltip>
        ) : (
          <WalkthroughTooltip wid="next-game">
            <ActionButton
              text="Next Question"
              onPress={() => this.props.newGame()}
            />
          </WalkthroughTooltip>
        )}
      </View>
    );
  }

  async share(isCorrectAnswer, target, numbers, equation, hints) {
    let search = '';
    const displayNumbers = numbers.map((n) => n.value);
    if (equation) {
      search = `?sample=${encodeURIComponent(equation)}`;
      if (hints) {
        search += `&hints=${encodeURIComponent(
          hints
            .map((hint) => `${hint.value1}${hint.operator}${hint.value2}`)
            .join('_'),
        )}`;
      }
    }
    const url = `https://www.equationate.com/solveGame/${target}_${displayNumbers.join(
      '_',
    )}${search}`;
    const lastNumber = displayNumbers.pop();
    let message = `${
      isCorrectAnswer ? 'I was able to' : 'Can you'
    } equationate ${displayNumbers.join(
      ', ',
    )} and ${lastNumber} to make ${target} with the operators +, -, ×, ÷ and xʸ${
      isCorrectAnswer ? '.' : '?'
    }`;
    if (equation) {
      message += ` ${
        isCorrectAnswer ? 'My solution was' : 'One solution is'
      } ${equationToString(equation)} = ${target}.`;
    }
    message += `\n\n${url}`;
    try {
      const result = await Share.share({title: 'Equationate', message});
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          log.info('Shared', result.activityType);
        } else {
          log.info('Shared no type');
        }
      } else if (result.action === Share.dismissedAction) {
        log.info('Share dismissed');
      }
    } catch (error) {
      log.error('Share error', error.message);
    }
  }

  renderShare() {
    if (!canShare() || !this.props.initialNumbers) {
      return null;
    }
    let equation;
    let correctAnswer = false;
    if (this.props.isCorrectAnswer) {
      equation = this.props.currentNumbers[0].equation;
      correctAnswer = true;
    } else {
      equation = this.props.sampleResult;
    }
    return (
      <View>
        <ActionButton
          text={equation ? 'Share without Answer' : 'Share'}
          onPress={() =>
            this.share(
              correctAnswer,
              this.props.target,
              this.props.initialNumbers,
            )
          }
        />
        {equation ? (
          <ActionButton
            text="Share with Answer"
            onPress={() =>
              this.share(
                correctAnswer,
                this.props.target,
                this.props.initialNumbers,
                equation,
                this.props.initialHints,
              )
            }
          />
        ) : null}
      </View>
    );
  }

  renderShowAnswer({title, requiresNewGame} = {}) {
    return (
      <View style={styles.container}>
        {title ? <Text style={styles.endGameText}>{title}</Text> : null}
        {this.props.sampleResult ? (
          <Text style={[styles.endGameText, {fontSize: scaleW(32)}]}>
            Here is one solution:
          </Text>
        ) : null}
        {this.props.sampleResult ? (
          <View style={styles.equation}>
            <Equation
              equation={`${this.props.sampleResult} = ${this.props.target}`}
              isLarger={true}
            />
          </View>
        ) : null}
        {this.renderShare()}
        {this.renderNewGame(requiresNewGame)}
      </View>
    );
  }

  renderGameState() {
    if (this.props.currentNumbers.length === 0) {
      return (
        <View style={styles.container}>
          <Text style={styles.endGameText}>Equationate</Text>
          {this.renderNewGame(!this.props.inGame)}
        </View>
      );
    } else if (this.props.isCorrectAnswer) {
      return (
        <View style={styles.container}>
          <AnimatedMessage style={styles.winnerTextWrapper}>
            <Text style={styles.winnerText}>You Got It!</Text>
          </AnimatedMessage>
          <View style={styles.equation}>
            <Equation
              equation={`${this.props.currentNumbers[0].equation} = ${this.props.currentNumbers[0].value}`}
              isLarger={true}
            />
          </View>
          {this.renderShare()}
          {this.renderNewGame()}
        </View>
      );
    } else if (this.props.didGiveUp) {
      return this.renderShowAnswer({
        title: "You didn't get it, too bad",
        requiresNewGame: true,
      });
    } else if (this.props.didShowAnswer) {
      return this.renderShowAnswer();
    } else if (this.props.didTimeOut) {
      return this.renderShowAnswer({title: "Time's Up", requiresNewGame: true});
    } else if (this.props.currentNumbers.length === 1) {
      return (
        <View style={styles.container}>
          {this.renderTarget()}
          <Text style={styles.endGameText}>Wrong!</Text>
          <View style={styles.equation}>
            <Equation
              equation={`${this.props.currentNumbers[0].equation} = ${this.props.currentNumbers[0].value}`}
              isLarger={true}
            />
          </View>
        </View>
      );
    } else if (!this.props.target) {
      return <Loading />;
    } else {
      return this.renderActiveGame();
    }
  }

  renderConfirmGiveUp() {
    if (!this.props.isShowingGiveUpConfirm) {
      return null;
    }
    return (
      <Modal transparent style={styles.modal}>
        <View style={styles.modalWrapper}>
          <View style={styles.modalContent}>
            <Text style={styles.giveUpText}>
              Are you sure you want to Give Up?
            </Text>
            <Button title="Yes" onPress={() => this.props.giveUp()} />
            <Button
              title="Close"
              onPress={() => this.props.cancelGiveUpConfirm()}
            />
          </View>
        </View>
      </Modal>
    );
  }

  renderShowAvailableDifficulty() {
    if (!this.props.isShowingDifficultyRestrictions) {
      return null;
    }
    return (
      <Modal transparent style={styles.modal}>
        <View style={styles.modalWrapper}>
          <View style={styles.modalContentFit}>
            <DifficultyRestrictions
              correctAnswers={this.props.correctAnswers}
            />
            <Button
              title="Close"
              onPress={() => this.props.clearShowDifficultyRestrictions()}
            />
          </View>
        </View>
      </Modal>
    );
  }

  render() {
    return (
      <View style={styles.topContainer}>
        {this.renderConfirmGiveUp()}
        {this.renderShowAvailableDifficulty()}
        <View style={styles.edgePaddingTop} />
        <View style={styles.gameState}>{this.renderGameState()}</View>
        <View style={styles.edgePaddingBottom} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  topContainer: {
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  gameState: {
    width: '100%',
    height: '90%',
    flex: 9,
    alignItems: 'center',
    justifyContent: 'center',
  },
  targetArea: {
    width: '100%',
    height: '10%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  undoArea: {
    top: 0,
    right: 50,
    position: 'absolute',
    width: 50,
    height: 50,
  },
  timerArea: {
    top: 0,
    left: 50,
    position: 'absolute',
    width: 50,
    height: 50,
  },
  newGameArea: {
    width: '100%',
    height: '40%',
    flex: 4,
    alignItems: 'center',
  },
  selectionArea: {
    width: '100%',
    maxWidth: 600,
    height: '62%',
    flex: 6,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingTop: 10,
  },
  selectionPanel: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  targetWrapper: {
    width: 160,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
  },
  targetBackgroundWrapper: {
    ...StyleSheet.absoluteFillObject,
    overflow: 'visible',
  },
  targetText: {
    fontFamily: 'Chewy-Regular',
    color: '#f6f5f5',
    fontSize: 60,
    // fontWeight: 'bold',
    textShadowOffset: {width: 2, height: 2},
    textShadowColor: 'black',
    textShadowRadius: 1,
    zIndex: 1,
  },
  answerInfoText: {
    fontFamily: 'Chewy-Regular',
    color: '#f9f9f9',
    fontSize: 20,
    // fontWeight: 'bold',
    textShadowOffset: {width: 1, height: 1},
    textShadowColor: 'black',
    textShadowRadius: 1,
  },
  winnerTextWrapper: {
    flex: 1,
    height: 40,
  },
  winnerText: {
    fontFamily: 'Chewy-Regular',
    color: '#ffd300',
    fontSize: 48,
    // fontWeight: 'bold',
    height: '100%',
    textShadowOffset: {width: 2, height: 2},
    textShadowColor: 'black',
    textShadowRadius: 1,
  },
  endGameText: {
    fontFamily: 'Chewy-Regular',
    flex: 1,
    color: '#f9f9f9',
    fontSize: 35,
    height: '20%',
    textShadowOffset: {width: 2, height: 2},
    textShadowColor: 'black',
    textShadowRadius: 1,
  },
  correctAnswers: {
    fontFamily: 'Chewy-Regular',
    flex: 1,
    color: '#f9f9f9',
    fontSize: 30,
    height: '20%',
    textShadowOffset: {width: 2, height: 2},
    textShadowColor: 'black',
    textShadowRadius: 1,
  },
  equation: {
    flex: 0.5,
    height: '10%',
  },
  answerArea: {
    width: '100%',
    height: '10%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  giveUpArea: {
    width: '100%',
    height: '10%',
    maxWidth: 600,
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  edgePaddingTop: {
    width: '100%',
    height: '5%',
    flex: 0.5,
  },
  edgePaddingBottom: {
    width: '100%',
    height: '1.5%',
    flex: 0.15,
  },
  modal: {
    width: '100%',
    height: '100%',
  },
  modalWrapper: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#00000080',
  },
  modalContent: {
    width: 200,
    height: 200,
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContentFit: {
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  giveUpText: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: 20,
  },
});

const mapStateToProps = (state) => {
  // log.debug('state', state);
  const {gameState, player} = state;
  return {
    ...(gameState.solveGame || gameState.walkthrough || gameState),
    ...player,
    ...(gameState.walkthrough
      ? {
          correctAnswers: 0,
          items: {
            hints: {
              collected: 3,
              used: 0,
            },
            answers: {
              collected: 3,
              used: 0,
            },
            lives: {
              collected: 0,
              used: 0,
              availableTime: [0, 0, 0],
            },
          },
        }
      : {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectNumber,
      selectOperator,
      undo,
      useHint,
      giveUp,
      showGiveUpConfirm,
      cancelGiveUpConfirm,
      showAnswer,
      newGame,
      timeUp,
      setLevel,
      setGameDuration,
      selectScreen,
      reset,
      endSolveGame,
      resetGame,
      showDifficultyRestrictions,
      clearShowDifficultyRestrictions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Game);
