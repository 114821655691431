import GameNumber from '../GameNumber';

export const gameWon = (
  level,
  gameDuration,
  finalNumber,
  elapsed,
  didShowAnswer,
  date,
) => ({
  type: 'GAME_WON',
  payload: {
    level,
    gameDuration,
    finalNumber,
    elapsed,
    didShowAnswer,
    now: Date.now(),
    date,
  },
});

export const startWalkthrough = () => ({
  type: 'START_WALKTHROUGH',
});

export const endWalkthrough = () => ({
  type: 'END_WALKTHROUGH',
});

export const stashWalkthrough = () => ({
  type: 'STASH_WALKTHROUGH',
});

export const stashPopWalkthrough = () => ({
  type: 'STASH_POP_WALKTHROUGH',
});

export const startSolveGame = (target, numbers, hints, sampleResult) => ({
  type: 'START_SOLVE_GAME',
  payload: {
    target,
    numbers: numbers.map((number) => new GameNumber(number)),
    hints,
    sampleResult,
    now: Date.now(),
  },
});

export const endSolveGame = () => ({
  type: 'END_SOLVE_GAME',
});

export const resetGame = () => ({
  type: 'RESET_GAME',
  payload: {
    now: Date.now(),
  },
});
