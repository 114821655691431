import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import AwesomeButton from 'react-native-really-awesome-button';
import LinearGradient from 'react-native-linear-gradient';
import WalkthroughTooltip from './WalkthroughTooltip';
import levels from './difficultyLevels';
import {registerChange, scaleH, scaleW, scaleMin} from './scaleUtils';
import {isCombinationEnabled} from './levelUtils';

export default class DifficultyLevelButtons extends React.Component {
  componentDidMount() {
    registerChange(this);
  }

  componentWillUnmount() {
    registerChange(this, true);
  }

  gradientColours(isSelected, isEnabled) {
    if (!isEnabled) {
      return ['#F7F7F7', '#C0C0C0', '#909090'];
    }
    if (isSelected) {
      return ['#F8F9FA', '#3B824C', '#43944E'];
    }
    return ['#F7F7F7', '#2C6C9D', '#3A8CCD'];
  }

  renderButton(level) {
    const isEnabled = isCombinationEnabled(
      level.level,
      this.props.gameDuration,
      this.props.correctAnswers,
    );
    return (
      <WalkthroughTooltip
        wid={`level-${level.name.toLowerCase()}`}
        key={level.level}>
        <AwesomeButton
          borderRadius={scaleMin(15, this.state)}
          height={scaleH(80, this.state)}
          width={scaleW(73, this.state)}
          raiseLevel={3}
          backgroundDarker="#202020"
          springRelease={false}
          style={styles.button}
          onPress={() =>
            isEnabled
              ? this.props.setLevel(level.level)
              : this.props.showRestrictions()
          }>
          <LinearGradient
            width="100%"
            height="100%"
            style={styles.innerView}
            colors={this.gradientColours(
              level.level === this.props.level,
              isEnabled,
            )}>
            <View style={styles.innerView} opacity={isEnabled ? 1 : 0.5}>
              <Text
                style={[styles.symbol, {fontSize: scaleMin(36, this.state)}]}>
                {level.symbol}
              </Text>
              <Text style={[styles.text, {fontSize: scaleMin(14, this.state)}]}>
                {level.name}
              </Text>
            </View>
          </LinearGradient>
        </AwesomeButton>
      </WalkthroughTooltip>
    );
  }

  render() {
    return (
      <View style={styles.view}>
        {levels.map((level) => this.renderButton(level))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    width: '100%',
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  button: {
    marginLeft: 5,
    marginRight: 5,
  },
  innerView: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  symbol: {
    color: 'white',
    fontSize: 36,
    textAlign: 'center',
    fontWeight: 'bold',
    textShadowOffset: {width: 1, height: 1},
    textShadowColor: 'black',
    textShadowRadius: 1,
  },
  text: {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    textShadowOffset: {width: 1, height: 1},
    textShadowColor: 'black',
    textShadowRadius: 1,
  },
});
