const steps = [
  {
    bannerTitle: 'Welcome to Equationate!',
    text:
      'The fun game where try to make a set of numbers equal a target number applying mathematical operators',
    buttons: ['START', 'SKIP'],
  },
  {
    wid: 'level-medium',
    tip:
      'Select the difficulty level, the harder the difficulty you choose the more points you score',
  },
  {
    wid: 'duration-timeless',
    tip:
      'Select the game duration, ⚡ is 30s a game, ⏱ is 300s a game and ⌛ is unlimited',
  },
  {
    wid: 'new-game',
    tip: 'Press "New Game" to start',
  },
  {
    wid: 'number-0',
    tip:
      "The aim is to use all of the numbers to make an equation that equals the number in the target (30), let's do 2×3 first so tap on 2",
    placement: 'bottom',
  },
  {
    wid: 'operator-*',
    tip: 'Then ×',
  },
  {
    wid: 'number-2',
    tip: 'Then 3',
  },
  {
    wid: 'number-3',
    tip: 'Now we will do 4×9 so tap on the 4',
  },
  {
    wid: 'operator-+',
    tip: 'Then +',
  },
  {
    wid: 'number-2',
    tip: 'Then 9',
  },
  {
    wid: 'undo',
    tip:
      'Oops! I meant 4×9, not 4+9, tap the undo button to undo the last step',
    placement: 'bottom',
  },
  {
    wid: 'number-3',
    tip: 'Now tap on the 4 again',
  },
  {
    wid: 'operator-*',
    tip: 'Then ×',
  },
  {
    wid: 'number-2',
    tip: 'Then 9',
  },
  {
    wid: 'operator--',
    tip:
      'The result of the last equation is automatically selected in orange, so to do 36-6 we just need to tap -',
  },
  {
    wid: 'number-0',
    tip: 'Then 6',
  },
  {
    wid: 'operator-*',
    tip: 'All of the numbers need to be used, so press ×',
  },
  {
    wid: 'number-1',
    tip: 'Then 1',
  },
  {
    wid: 'next-game',
    tip: 'Press "Next Question" to go again',
  },
  {
    wid: 'hint',
    tip:
      'You can earn hints and correct answers by going up levels or buying them from the shop, if you have hints to use you can click the "Give Me A Hint" button to get a hint',
  },
  {
    wid: 'answer',
    tip:
      'If you have correct answers you can press the "Show Answer" button to show a possible answer to the question',
  },
  {
    bannerTitle: 'Good Luck!',
    timeout: 3000,
    buttons: ['END'],
  },
];

export function currentWalkthrough(state) {
  if (state && !state.clearingModal && state.currentStep < steps.length) {
    return steps[state.currentStep];
  }
  return null;
}

export function isCurrentLast(state) {
  return state && state.currentStep >= steps.length - 1;
}
