import './App.css';
import React from 'react';
import {ImageBackground, StyleSheet} from 'react-native';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import reduxThunk from 'redux-thunk';
import reducer from '../../reducers';
import Game from '../../Game';
import StartWalkthrough from './StartWalkthrough';

const store = createStore(
  reducer,
  compose(
    applyMiddleware(reduxThunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f,
  ),
);

function App() {
  return (
    <ImageBackground
      source={require('../../assets/background.jpg')}
      style={styles.app}>
      <Provider store={store}>
        <StartWalkthrough>
          <Game />
        </StartWalkthrough>
      </Provider>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  app: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default App;
