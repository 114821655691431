import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {Svg, Circle} from 'react-native-svg';

export default class GameTimer extends React.Component {
  constructor(props) {
    super(props);
    this.refreshTimer = null;
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimer);
  }

  calcTime() {
    const now = Date.now();
    return Math.floor(
      (this.props.gameStart + this.props.gameDuration * 1000 - now) / 1000,
    );
  }

  render() {
    let time = this.calcTime();
    if (time < 0) {
      time = 0;
      this.props.timeUp();
    }
    clearTimeout(this.refreshTimer);
    if (time >= 0) {
      this.refreshTimer = setTimeout(() => this.forceUpdate(), 1000);
    }
    return (
      <View style={styles.view}>
        <Svg
          height="70"
          width="70"
          viewBox="-150 -150 300 300"
          style={styles.circle}>
          <Circle
            x="0"
            y="0"
            fill="#FFF"
            r="100"
            stroke="#2C6C9D"
            strokeWidth="20"
            fillOpacity=".5"
          />
        </Svg>
        <Text style={styles.text}>{time}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    width: 70,
    height: 70,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: '#f9f9f9',
    fontSize: 25,
    fontWeight: 'bold',
    textShadowOffset: {width: 1, height: 1},
    textShadowColor: 'black',
    textShadowRadius: 1,
    zIndex: 1,
  },
  circle: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
});
