import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import AwesomeButton from 'react-native-really-awesome-button';
import LinearGradient from 'react-native-linear-gradient';
import WalkthroughTooltip from './WalkthroughTooltip';
import durations from './gameDurations';
import {isCombinationEnabled} from './levelUtils';

export default class GameDurationButtons extends React.Component {
  gradientColours(isSelected, isEnabled) {
    if (!isEnabled) {
      return ['#F7F7F7', '#C0C0C0', '#909090'];
    }
    if (isSelected) {
      return ['#F8F9FA', '#3B824C', '#43944E'];
    }
    return ['#F7F7F7', '#2C6C9D', '#3A8CCD'];
  }

  renderButton(duration) {
    const isEnabled = isCombinationEnabled(
      this.props.level,
      duration.duration,
      this.props.correctAnswers,
    );
    return (
      <WalkthroughTooltip
        wid={`duration-${duration.name.toLowerCase()}`}
        key={duration.name}>
        <AwesomeButton
          borderRadius={15}
          height={80}
          width={95}
          raiseLevel={3}
          backgroundDarker="#202020"
          springRelease={false}
          style={styles.button}
          onPress={() =>
            isEnabled
              ? this.props.setGameDuration(duration.duration)
              : this.props.showRestrictions()
          }>
          <LinearGradient
            width="100%"
            height="100%"
            style={styles.innerView}
            colors={this.gradientColours(
              duration.duration === this.props.gameDuration,
              isEnabled,
            )}>
            <View style={styles.innerView} opacity={isEnabled ? 1 : 0.5}>
              <Text style={styles.symbol}>{duration.symbol}</Text>
              <Text style={styles.text}>{duration.name}</Text>
            </View>
          </LinearGradient>
        </AwesomeButton>
      </WalkthroughTooltip>
    );
  }

  render() {
    return (
      <View style={styles.view}>
        {durations.map((duration) => this.renderButton(duration))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    width: '100%',
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  button: {
    marginLeft: 5,
    marginRight: 5,
  },
  innerView: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  symbol: {
    color: 'white',
    fontSize: 36,
    textAlign: 'center',
    fontWeight: 'bold',
    textShadowOffset: {width: 1, height: 1},
    textShadowColor: 'black',
    textShadowRadius: 1,
  },
  text: {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    textShadowOffset: {width: 1, height: 1},
    textShadowColor: 'black',
    textShadowRadius: 1,
  },
});
