import {REHYDRATE} from 'redux-persist';
import difficultyLevels from '../difficultyLevels';
import achievements from '../achievements';
import playerLevels from '../playerLevels';
import {availableCoins, dateCoins, MAX_DAILY} from '../coinUtils';
import log from '../log';

const INITIAL_STATE = {
  points: 0,
  authUser: null,
  isAnonymous: false,
  email: null,
  isFederated: false,
  userLoaded: false,
  syncFailed: false,
  playerLevel: 1,
  numberOfCoinsCollected: 0,
  numberOfCoinsUsed: 0,
  coinsEarned: {},
  addedItems: [],
  items: {
    hints: {
      collected: 3,
      used: 0,
    },
    answers: {
      collected: 3,
      used: 0,
    },
    lives: {
      collected: 0,
      used: 0,
      availableTime: [0, 0, 0],
    },
  },
  config: {},
  forceUpdateLink: null,
  addedAchievements: [],
  levelUp: null,
  clearedLevelUp: false,
  achievements: {},
  storeError: null,
  storeConfirmation: null,
  accountError: null,
  showingConfirmDelete: false,
  gameID: null,
  correctAnswers: 0,
  isSolveGame: false,
  isWalkthrough: false,
  hasCompletedWalkthrough: false,
  soundEnabled: true,
  products: [],
};

export const migrations = {
  0: (playerState) => ({
    ...playerState,
    items: {
      ...playerState.items,
      hints: {
        count: (playerState.items.hints.extra || 0) + 3,
      },
      answers: {
        count: (playerState.items.answers.extra || 0) + 3,
      },
    },
  }),
  1: (playerState) => ({
    ...playerState,
    numberOfCoinsCollected: playerState.numberOfCoins,
    numberOfCoinsUsed: 0,
    items: {
      ...playerState.items,
      hints: {
        collected: playerState.items.hints.count || 0,
        used: 0,
      },
      answers: {
        collected: playerState.items.answers.count || 0,
        used: 0,
      },
      lives: {
        collected: playerState.items.lives.extra || 0,
        used: 0,
        availableTime: playerState.items.lives.availableTime,
      },
    },
  }),
};

function addItem(state, name, numToAdd, now) {
  let stateValues = state.items[name];
  if (!stateValues) {
    stateValues = INITIAL_STATE.items[name];
  }
  if (!stateValues.availableTime) {
    return {
      collected: stateValues.collected + numToAdd,
    };
  }
  const numUsed = stateValues.availableTime.filter((time) => time > now).length;
  const stateUpdate = {};
  if (numUsed > 0) {
    const numToMakeAvailable = Math.min(numUsed, numToAdd);
    stateUpdate.availableTime = stateValues.availableTime
      .map((availableTime, index) =>
        index >= stateValues.availableTime.length - numToMakeAvailable
          ? 0
          : availableTime,
      )
      .sort();
    if (numToMakeAvailable < numToAdd) {
      stateUpdate.collected =
        stateValues.collected + numToAdd - numToMakeAvailable;
    }
  } else {
    stateUpdate.collected = stateValues.collected + numToAdd;
  }
  return stateUpdate;
}

function useItem(state, name, timeout, now) {
  if (!state.items[name].availableTime) {
    return {
      ...state,
      items: {
        ...state.items,
        [name]: {
          ...state.items[name],
          used: state.items[name].used + 1,
        },
      },
    };
  }
  if (state.items[name].collected - state.items[name].used > 0) {
    return {
      ...state,
      items: {
        ...state.items,
        [name]: {
          ...state.items[name],
          used: state.items[name].used + 1,
        },
      },
    };
  }
  return {
    ...state,
    items: {
      ...state.items,
      [name]: {
        ...state.items[name],
        availableTime: [
          ...state.items[name].availableTime.slice(1),
          now + timeout,
        ],
      },
    },
  };
}

function updateAchievementState(
  prevState,
  finalNumber,
  level,
  gameDuration,
  elapsed,
) {
  const achievementState = {...prevState};
  const addedAchievements = [];
  achievements.forEach((achievement) => {
    const wasCompleted =
      prevState[achievement.name] && prevState[achievement.name].completed;
    achievementState[achievement.name] = achievement.checkUpdate(
      prevState[achievement.name],
      finalNumber,
      level,
      gameDuration,
      elapsed,
    );
    if (!wasCompleted && achievementState[achievement.name].completed) {
      addedAchievements.push(achievement.title);
    }
  });
  return {
    achievements: achievementState,
    addedAchievements,
  };
}

function checkLevelUp(state, newPoints, addedItems, extraState, now) {
  const newLevel = playerLevels
    .slice()
    .reverse()
    .find((playerLevel) => newPoints >= playerLevel.points);
  const newState = {
    ...state,
    points: newPoints,
    playerLevel: newLevel.level,
    ...extraState,
  };
  if (newLevel.level > state.playerLevel) {
    newState.items = {
      ...state.items,
      hints: {...state.items.hints},
      answers: {...state.items.answers},
    };
    Object.assign(
      newState.items.hints,
      addItem(state, 'hints', newLevel.rewardHints, now),
    );
    Object.assign(
      newState.items.answers,
      addItem(state, 'answers', newLevel.rewardAnswers, now),
    );
    addedItems.push({name: 'Hints', amount: newLevel.rewardHints});
    addedItems.push({name: 'Answers', amount: newLevel.rewardAnswers});
    newState.levelUp = newLevel.level;
  }
  newState.addedItems = addedItems;
  return newState;
}

function filterAction(action) {
  if (action.type === 'SET_AUTH_USER') {
    return {
      ...action,
      payload: {
        player: {
          user: {
            username: action.payload.user.username,
            attributes: action.payload.user.attributes,
          },
        },
      },
    };
  }
  return action;
}

export default (state = INITIAL_STATE, action) => {
  log.debug('action', filterAction(action));
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.player) {
        const player = {
          ...action.payload.player,
          isWalkthrough: false,
          isSolveGame: false,
          forceUpdateLink: null,
        };
        return player;
        // return migrations[1](migrations[0](player));
      }
      return state;
    case 'GAME_WON': {
      if (
        !state.isWalkthrough &&
        !state.isSolveGame &&
        action.payload.didShowAnswer
      ) {
        state = useItem(state, 'answers');
      }
      if (state.isAnonymous || state.isWalkthrough || state.isSolveGame) {
        return state;
      }
      const addedPoints = difficultyLevels.find(
        (level) => level.level === action.payload.level,
      ).points;
      const addedItems = [{name: 'Points', amount: addedPoints}];
      const correctAnswers = (state.correctAnswers || 0) + 1;
      let addedCoins = correctAnswers % 5 === 0 ? 2 : 0;
      if (addedCoins > 0) {
        if (addedCoins + dateCoins(state, action.payload.date) > MAX_DAILY) {
          addedCoins = MAX_DAILY - dateCoins(state, action.payload.date);
          addedItems.push({name: 'Daily maximum coins reached'});
        }
      }
      if (addedCoins > 0) {
        addedItems.push({name: 'Coins', amount: addedCoins});
      }

      const achievementState = updateAchievementState(
        state.achievements,
        action.payload.finalNumber,
        action.payload.level,
        action.payload.gameDuration,
        action.payload.elapsed,
      );
      const coinsState = {
        ...state.coinsEarned,
        [action.payload.date]:
          ((state.coinsEarned || {})[action.payload.date] || 0) + addedCoins,
      };
      const newPoints = state.points + addedPoints;
      return checkLevelUp(
        {
          ...state,
          correctAnswers,
        },
        newPoints,
        addedItems,
        {...achievementState, coinsEarned: coinsState},
        action.payload.now,
        coinsState,
      );
    }
    case 'USE_HINT':
      if (state.isWalkthrough || state.isSolveGame) {
        return state;
      }
      return useItem(state, 'hints');
    case 'TEST_LEVEL':
      return {
        ...state,
        levelUp: 22,
      };
    case 'NEW_GAME':
      if (action.payload.newGameRequired && !state.isWalkthrough) {
        return useItem(
          {
            ...state,
            gameID: action.payload.gameID,
            correctAnswers: 0,
          },
          'lives',
          60 * 60 * 1000,
          action.payload.now,
        );
      }
      return state;
    case 'GIVE_UP':
    case 'TIME_UP':
      if (state.isWalkthrough || state.isSolveGame) {
        return state;
      }
      const achievementState = updateAchievementState(
        state.achievements,
        null,
        action.payload.level,
        action.payload.gameDuration,
        action.payload.elapsed,
      );
      return {
        ...state,
        correctAnswers: 0,
        ...achievementState,
      };
    case 'BUY_ITEMS':
      return {
        ...state,
        storeConfirmation: action.payload,
      };
    case 'CANCEL_BUY_ITEMS':
      return {
        ...state,
        storeConfirmation: null,
      };
    case 'CONFIRM_BUY_ITEMS': {
      if (availableCoins(state) < action.payload.cost) {
        return {
          ...state,
          storeConfirmation: null,
          storeError: 'Buy more coins',
        };
      }
      const newState = {
        ...state,
        storeConfirmation: null,
        numberOfCoinsUsed: state.numberOfCoinsUsed + action.payload.cost,
        items: {
          ...state.items,
        },
      };
      action.payload.items.forEach((item) => {
        newState.items[item.name] = Object.assign(
          {},
          state.items[item.name],
          addItem(state, item.name, item.number, action.payload.now),
        );
      });
      return newState;
    }
    case 'BUY_COINS':
      return {
        ...state,
        storeError: `${action.payload.numberOfCoins} coins added`,
        numberOfCoinsCollected:
          state.numberOfCoinsCollected + action.payload.numberOfCoins,
      };
    case 'CLEAR_STORE_ERROR':
      return {
        ...state,
        storeError: null,
      };
    case 'CLEAR_ADDED_ITEMS':
      return {
        ...state,
        addedItems: [],
        addedAchievements: [],
      };
    case 'CLEAR_LEVEL_UP':
      return {
        ...state,
        levelUp: null,
        clearedLevelUp: true,
      };
    case 'SET_ACCOUNT_ERROR':
      return {
        ...state,
        accountError: action.payload.error,
      };
    case 'CLEAR_ACCOUNT_ERROR':
      return {
        ...state,
        accountError: null,
        showingConfirmDelete: false,
      };
    case 'RESET':
      if (action.payload.didLevelUp) {
        return {
          ...state,
          clearedLevelUp: false,
        };
      }
      return {
        ...state,
        addedItems: [],
        addedAchievements: [],
      };
    case 'CREATE_PLAYER':
      return {
        ...INITIAL_STATE,
        userLoaded: true,
        authUser: state.user,
        email: state.email,
        isFederated: state.isFederated,
      };
    case 'SYNC_STATE': {
      const {player} = action.payload;
      log.info('SYNC_STATE', player);
      const rewardsCollected = {
        hints: 0,
        answers: 0,
        lives: 0,
      };
      Object.values(player.rewards).forEach((reward) => {
        if (!reward) {
          return;
        }
        Object.keys(rewardsCollected).forEach((rewardType) => {
          if (reward[rewardType]) {
            rewardsCollected[rewardType] += reward[rewardType];
          }
        });
      });
      const syncAchievements = {};
      Object.keys(player.achievements).forEach((achievementKey) => {
        if (player.achievements[achievementKey] === null) {
          return;
        }
        const match = achievementKey
          .replace(/_/g, '-')
          .match(/^ac-(.*)-([^-]*)$/);
        if (match) {
          const [, name, type] = match;
          if (!syncAchievements[name]) {
            syncAchievements[name] = {
              progress: 0,
              completed: false,
            };
            syncAchievements[name][type] = player.achievements[achievementKey];
          }
        }
      });
      const syncCompleteAchievements = [];
      achievements.forEach((achievementRequirements) => {
        const achievementName = achievementRequirements.name;
        if (
          syncAchievements[achievementName] &&
          !syncAchievements[achievementName].completed &&
          syncAchievements[achievementName].progress >=
            achievementRequirements.progressGoal
        ) {
          syncCompleteAchievements.push(achievementRequirements.title);
          syncAchievements[achievementName].completed = true;
        }
      });
      const coinsEarned = {};
      player.coinsEarned.forEach((earned) => {
        coinsEarned[earned.date] = earned.coins;
      });
      const newState = {
        ...state,
        id: player.id,
        points: player.points,
        playerLevel: player.playerLevel,
        numberOfCoinsCollected: player.numberOfCoinsEarned,
        numberOfCoinsUsed: player.numberOfCoinsSpent,
        items: {
          hints: {
            collected: player.numberOfHintsCollected + rewardsCollected.hints,
            used: player.numberOfHintsUsed,
          },
          answers: {
            collected:
              player.numberOfAnswersCollected + rewardsCollected.answers,
            used: player.numberOfAnswersUsed,
          },
          lives: {
            collected: player.numberOfLivesCollected + rewardsCollected.lives,
            used: player.numberOfLivesUsed,
            availableTime: state.items.lives.availableTime,
          },
        },
        achievements: syncAchievements,
        addedAchievements: [
          ...state.addedAchievements,
          ...syncCompleteAchievements,
        ],
        coinsEarned,
        config: player.config,
        forceUpdateLink: player.forceUpdateLink,
        userLoaded: true,
      };
      log.info('SYNC_STATE newState', newState);
      return checkLevelUp(
        newState,
        player.points,
        [...state.addedItems],
        {},
        action.payload.now,
      );
    }
    case 'SET_AUTH_USER': {
      const user = action.payload.user;
      log.info(
        'SET_AUTH_USER',
        user.username,
        state.authUser,
        user.username === state.authUser,
      );
      const isFederated =
        user &&
        user.signInUserSession &&
        user.signInUserSession.idToken &&
        user.signInUserSession.idToken.payload &&
        !!user.signInUserSession.idToken.payload.identities;
      return {
        ...state,
        authUser: user.username,
        email: user.attributes.email,
        userLoaded: user.username === state.authUser,
        isFederated,
        showingConfirmDelete: false,
        syncFailed: false,
      };
    }
    case 'SYNC_FAILED': {
      return {
        ...state,
        syncFailed: true,
      };
    }
    case 'SET_ANONYMOUS': {
      if (action.payload.isAnonymous && !state.isAnonymous) {
        return {
          ...INITIAL_STATE,
          userLoaded: true,
          isAnonymous: true,
        };
      }
      return {
        ...state,
        isAnonymous: action.payload.isAnonymous,
      };
    }
    case 'START_WALKTHROUGH':
      return {
        ...state,
        isWalkthrough: true,
        hasCompletedWalkthrough: true,
      };
    case 'END_WALKTHROUGH':
      return {
        ...state,
        isWalkthrough: false,
      };
    case 'STASH_WALKTHROUGH':
      if (state.isWalkthrough) {
        return {
          ...state,
          saveIsWalkthrough: true,
          isWalkthrough: false,
        };
      }
      return state;
    case 'STASH_POP_WALKTHROUGH':
      if (state.saveIsWalkthrough) {
        return {
          ...state,
          saveIsWalkthrough: null,
          isWalkthrough: true,
        };
      }
      return state;
    case 'START_SOLVE_GAME':
      return {
        ...state,
        isSolveGame: true,
      };
    case 'END_SOLVE_GAME':
      return {
        ...state,
        isSolveGame: false,
      };
    case 'SET_SOUND_ENABLED':
      return {
        ...state,
        soundEnabled: action.payload.value,
      };
    case 'SHOW_CONFIRM_DELETE_ACCOUNT':
      return {
        ...state,
        showingConfirmDelete: true,
      };
    case 'UPDATE_IAP_PRODUCTS':
      return {
        ...state,
        products: action.payload.products,
      };
    case 'IAP_ERROR':
      return {
        ...state,
        storeError: action.payload.error,
      };
    default:
      return state;
  }
};
