const superscript = {
  0: '⁰',
  1: '¹',
  2: '²',
  3: '³',
  4: '⁴',
  5: '⁵',
  6: '⁶',
  7: '⁷',
  8: '⁸',
  9: '⁹',
  '(': '⁽',
  ')': '⁾',
  '+': '⁺',
  '-': '⁻',
  '×': 'ˣ',
  ' ': ' ',
};

export function equationToString(sourceEquation) {
  const equation = sourceEquation.replace(/\*/g, '×').replace(/\//g, '÷');
  if (equation.indexOf('^') < 0) {
    return equation;
  }
  const parts = getParts(equation);
  return parts
    .map((part, index) => {
      if (index % 2 === 0) {
        return part;
      }
      return [...part].map((char) => superscript[char] || char).join('');
    })
    .join('');
}

export function getParts(equation) {
  let parts = [];
  let remaining = equation;
  do {
    const match = remaining.match(/^(.*?)\s*\^\s*(.*)$/);
    parts.push(match[1]);
    const rest = match[2];
    if (rest.match(/^\(/)) {
      let bracketCount = 1;
      let restIndex = 1;
      for (; bracketCount > 0 && restIndex < rest.length; ++restIndex) {
        if (rest[restIndex] === '(') {
          ++bracketCount;
        } else if (rest[restIndex] === ')') {
          --bracketCount;
        }
      }
      parts.push(rest.slice(0, restIndex));
      remaining = rest.slice(restIndex);
    } else {
      const restMatch = rest.match(/([^\s)]+)([)\s]+(.*))?$/);
      parts.push(restMatch[1]);
      remaining = restMatch[2] || '';
    }
  } while (remaining.includes('^'));
  if (remaining.length > 0) {
    parts.push(remaining);
  }
  return parts;
}
