import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {getParts} from './equationUtils';
import {registerChange, scaleW} from './scaleUtils';

export default class Equation extends React.Component {
  componentDidMount() {
    registerChange(this);
  }

  componentWillUnmount() {
    registerChange(this, true);
  }

  render() {
    if (!this.props.equation) {
      return null;
    }
    let equation = this.props.equation.replace(/\*/g, '×').replace(/\//g, '÷');
    const equationStyles = [styles.equation].concat(
      this.props.isLarger
        ? [styles.equationLarger, {fontSize: scaleW(33, this.state)}]
        : [{fontSize: scaleW(22, this.state)}],
    );
    if (equation.indexOf('^') < 0) {
      return (
        <Text style={equationStyles.concat([styles.negativeMargin])}>
          {equation}
        </Text>
      );
    } else {
      const equationSuperscriptStyles = [
        styles.equation,
        styles.superscript,
      ].concat(
        this.props.isLarger
          ? [
              styles.superscriptLarger,
              {
                fontSize: scaleW(17, this.state),
                lineHeight: scaleW(23, this.state),
              },
            ]
          : [
              {
                fontSize: scaleW(14, this.state),
                lineHeight: scaleW(16, this.state),
              },
            ],
      );
      const parts = getParts(equation);
      return (
        <View style={styles.wrapView}>
          {parts.map((part, index) => (
            <Text
              key={index}
              style={
                index % 2 === 0 ? equationStyles : equationSuperscriptStyles
              }>
              {part}
            </Text>
          ))}
        </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  equation: {
    fontFamily: 'Chewy-Regular',
    flex: 0,
    flexBasis: 'auto',
    color: 'white',
    fontSize: 18,
    textAlign: 'center',
    textShadowOffset: {width: 1, height: 1},
    textShadowColor: 'black',
    textShadowRadius: 1,
    marginTop: -5,
  },
  negativeMargin: {
    marginLeft: -10,
    marginRight: -10,
  },
  equationLarger: {
    fontSize: 30,
    textShadowOffset: {width: 2, height: 2},
    // fontWeight: 'bold',
  },
  superscript: {
    fontSize: 10,
    lineHeight: 12,
  },
  superscriptLarger: {
    fontSize: 15,
    lineHeight: 20,
  },
  wrapView: {
    flexDirection: 'row',
    flexBasis: 'auto',
  },
});
