import GameNumber from './GameNumber';

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const games = {
  1: [
    {
      target: '5',
      numbers: [1, 1, 1, 1, 1],
      sampleResult: '((((1 + 1) + 1) + 1) + 1)',
      hints: [
        {
          value1: '1',
          value2: '1',
          operator: '+',
        },
        {
          value1: '2',
          value2: '1',
          operator: '+',
        },
        {
          value1: '3',
          value2: '1',
          operator: '+',
        },
      ],
    },
    {
      target: '16',
      numbers: [1, 1, 2, 2, 9],
      sampleResult: '((((1 + 2) * 2) + 1) + 9)',
      hints: [
        {
          value1: '1',
          value2: '2',
          operator: '+',
        },
        {
          value1: '3',
          value2: '2',
          operator: '*',
        },
        {
          value1: '6',
          value2: '1',
          operator: '+',
        },
      ],
    },
    {
      target: '19',
      numbers: [1, 1, 3, 3, 9],
      sampleResult: '((((3 * 3) + 1) + 9) * 1)',
      hints: [
        {
          value1: '3',
          value2: '3',
          operator: '*',
        },
        {
          value1: '9',
          value2: '1',
          operator: '+',
        },
        {
          value1: '10',
          value2: '9',
          operator: '+',
        },
      ],
    },
    {
      target: '16',
      numbers: [1, 1, 4, 7, 9],
      sampleResult: '((((1 + 1) + 9) - 7) * 4)',
      hints: [
        {
          value1: '1',
          value2: '1',
          operator: '+',
        },
        {
          value1: '2',
          value2: '9',
          operator: '+',
        },
        {
          value1: '11',
          value2: '7',
          operator: '-',
        },
      ],
    },
    {
      target: '23',
      numbers: [1, 2, 2, 3, 5],
      sampleResult: '((((2 + 2) * 5) + 3) * 1)',
      hints: [
        {
          value1: '2',
          value2: '2',
          operator: '+',
        },
        {
          value1: '4',
          value2: '5',
          operator: '*',
        },
        {
          value1: '20',
          value2: '3',
          operator: '+',
        },
      ],
    },
    {
      target: '17',
      numbers: [1, 2, 3, 4, 8],
      sampleResult: '((((2 + 3) + 4) + 8) * 1)',
      hints: [
        {
          value1: '2',
          value2: '3',
          operator: '+',
        },
        {
          value1: '5',
          value2: '4',
          operator: '+',
        },
        {
          value1: '9',
          value2: '8',
          operator: '+',
        },
      ],
    },
    {
      target: '30',
      numbers: [1, 2, 5, 5, 7],
      sampleResult: '((((5 + 7) * 2) + 1) + 5)',
      hints: [
        {
          value1: '5',
          value2: '7',
          operator: '+',
        },
        {
          value1: '12',
          value2: '2',
          operator: '*',
        },
        {
          value1: '24',
          value2: '1',
          operator: '+',
        },
      ],
    },
    {
      target: '8',
      numbers: [1, 3, 3, 5, 8],
      sampleResult: '((((1 + 5) + 8) - 3) - 3)',
      hints: [
        {
          value1: '1',
          value2: '5',
          operator: '+',
        },
        {
          value1: '6',
          value2: '8',
          operator: '+',
        },
        {
          value1: '14',
          value2: '3',
          operator: '-',
        },
      ],
    },
    {
      target: '9',
      numbers: [1, 3, 6, 6, 9],
      sampleResult: '((((1 + 3) * 6) - 6) - 9)',
      hints: [
        {
          value1: '1',
          value2: '3',
          operator: '+',
        },
        {
          value1: '4',
          value2: '6',
          operator: '*',
        },
        {
          value1: '24',
          value2: '6',
          operator: '-',
        },
      ],
    },
    {
      target: '9',
      numbers: [1, 4, 6, 7, 9],
      sampleResult: '((((1 + 4) + 6) + 7) - 9)',
      hints: [
        {
          value1: '1',
          value2: '4',
          operator: '+',
        },
        {
          value1: '5',
          value2: '6',
          operator: '+',
        },
        {
          value1: '11',
          value2: '7',
          operator: '+',
        },
      ],
    },
    {
      target: '7',
      numbers: [1, 7, 7, 8, 9],
      sampleResult: '((((1 + 7) - 8) * 9) + 7)',
      hints: [
        {
          value1: '1',
          value2: '7',
          operator: '+',
        },
        {
          value1: '8',
          value2: '8',
          operator: '-',
        },
        {
          value1: '0',
          value2: '9',
          operator: '*',
        },
      ],
    },
    {
      target: '30',
      numbers: [2, 2, 3, 3, 3],
      sampleResult: '((((2 + 2) + 3) + 3) * 3)',
      hints: [
        {
          value1: '2',
          value2: '2',
          operator: '+',
        },
        {
          value1: '4',
          value2: '3',
          operator: '+',
        },
        {
          value1: '7',
          value2: '3',
          operator: '+',
        },
      ],
    },
    {
      target: '49',
      numbers: [2, 2, 4, 7, 7],
      sampleResult: '((((2 + 2) + 7) - 4) * 7)',
      hints: [
        {
          value1: '2',
          value2: '2',
          operator: '+',
        },
        {
          value1: '4',
          value2: '7',
          operator: '+',
        },
        {
          value1: '11',
          value2: '4',
          operator: '-',
        },
      ],
    },
    {
      target: '7',
      numbers: [2, 3, 3, 5, 7],
      sampleResult: '((((2 + 3) - 5) * 3) + 7)',
      hints: [
        {
          value1: '2',
          value2: '3',
          operator: '+',
        },
        {
          value1: '5',
          value2: '5',
          operator: '-',
        },
        {
          value1: '0',
          value2: '3',
          operator: '*',
        },
      ],
    },
    {
      target: '8',
      numbers: [2, 3, 7, 8, 8],
      sampleResult: '((((2 + 8) + 8) - 3) - 7)',
      hints: [
        {
          value1: '2',
          value2: '8',
          operator: '+',
        },
        {
          value1: '10',
          value2: '8',
          operator: '+',
        },
        {
          value1: '18',
          value2: '3',
          operator: '-',
        },
      ],
    },
    {
      target: '8',
      numbers: [2, 5, 5, 8, 8],
      sampleResult: '((((2 + 8) + 8) - 5) - 5)',
      hints: [
        {
          value1: '2',
          value2: '8',
          operator: '+',
        },
        {
          value1: '10',
          value2: '8',
          operator: '+',
        },
        {
          value1: '18',
          value2: '5',
          operator: '-',
        },
      ],
    },
    {
      target: '20',
      numbers: [3, 3, 3, 7, 9],
      sampleResult: '((((3 + 3) * 3) + 9) - 7)',
      hints: [
        {
          value1: '3',
          value2: '3',
          operator: '+',
        },
        {
          value1: '6',
          value2: '3',
          operator: '*',
        },
        {
          value1: '18',
          value2: '9',
          operator: '+',
        },
      ],
    },
    {
      target: '23',
      numbers: [3, 4, 4, 6, 6],
      sampleResult: '((((3 + 4) + 4) + 6) + 6)',
      hints: [
        {
          value1: '3',
          value2: '4',
          operator: '+',
        },
        {
          value1: '7',
          value2: '4',
          operator: '+',
        },
        {
          value1: '11',
          value2: '6',
          operator: '+',
        },
      ],
    },
    {
      target: '8',
      numbers: [4, 4, 4, 4, 7],
      sampleResult: '((((4 + 4) - 7) * 4) + 4)',
      hints: [
        {
          value1: '4',
          value2: '4',
          operator: '+',
        },
        {
          value1: '8',
          value2: '7',
          operator: '-',
        },
        {
          value1: '1',
          value2: '4',
          operator: '*',
        },
      ],
    },
    {
      target: '13',
      numbers: [4, 7, 7, 8, 9],
      sampleResult: '((((7 + 8) + 9) - 4) - 7)',
      hints: [
        {
          value1: '7',
          value2: '8',
          operator: '+',
        },
        {
          value1: '15',
          value2: '9',
          operator: '+',
        },
        {
          value1: '24',
          value2: '4',
          operator: '-',
        },
      ],
    },
  ],
  2: [
    {
      target: '27',
      numbers: [1, 1, 1, 1, 3],
      sampleResult: '((((1 + 1) + 1) * 1) ^ 3)',
      hints: [
        {
          value1: '1',
          value2: '1',
          operator: '+',
        },
        {
          value1: '2',
          value2: '1',
          operator: '+',
        },
        {
          value1: '3',
          value2: '1',
          operator: '*',
        },
      ],
    },
    {
      target: '29',
      numbers: [1, 1, 3, 3, 8],
      sampleResult: '((((3 * 8) + 1) + 1) + 3)',
      hints: [
        {
          value1: '3',
          value2: '8',
          operator: '*',
        },
        {
          value1: '24',
          value2: '1',
          operator: '+',
        },
        {
          value1: '25',
          value2: '1',
          operator: '+',
        },
      ],
    },
    {
      target: '34',
      numbers: [1, 1, 6, 7, 8],
      sampleResult: '((((1 + 6) - 1) * 7) - 8)',
      hints: [
        {
          value1: '1',
          value2: '6',
          operator: '+',
        },
        {
          value1: '7',
          value2: '1',
          operator: '-',
        },
        {
          value1: '6',
          value2: '7',
          operator: '*',
        },
      ],
    },
    {
      target: '25',
      numbers: [1, 2, 3, 4, 4],
      sampleResult: '((((1 + 2) + 4) * 3) + 4)',
      hints: [
        {
          value1: '1',
          value2: '2',
          operator: '+',
        },
        {
          value1: '3',
          value2: '4',
          operator: '+',
        },
        {
          value1: '7',
          value2: '3',
          operator: '*',
        },
      ],
    },
    {
      target: '23',
      numbers: [1, 2, 5, 5, 6],
      sampleResult: '((((1 + 5) * 2) + 5) + 6)',
      hints: [
        {
          value1: '1',
          value2: '5',
          operator: '+',
        },
        {
          value1: '6',
          value2: '2',
          operator: '*',
        },
        {
          value1: '12',
          value2: '5',
          operator: '+',
        },
      ],
    },
    {
      target: '28',
      numbers: [1, 3, 3, 5, 5],
      sampleResult: '((((1 + 3) * 5) + 3) + 5)',
      hints: [
        {
          value1: '1',
          value2: '3',
          operator: '+',
        },
        {
          value1: '4',
          value2: '5',
          operator: '*',
        },
        {
          value1: '20',
          value2: '3',
          operator: '+',
        },
      ],
    },
    {
      target: '27',
      numbers: [1, 3, 5, 8, 8],
      sampleResult: '((((5 - 1) * 8) + 3) - 8)',
      hints: [
        {
          value1: '5',
          value2: '1',
          operator: '-',
        },
        {
          value1: '4',
          value2: '8',
          operator: '*',
        },
        {
          value1: '32',
          value2: '3',
          operator: '+',
        },
      ],
    },
    {
      target: '32',
      numbers: [1, 4, 5, 9, 9],
      sampleResult: '((((4 * 9) + 5) - 9) * 1)',
      hints: [
        {
          value1: '4',
          value2: '9',
          operator: '*',
        },
        {
          value1: '36',
          value2: '5',
          operator: '+',
        },
        {
          value1: '41',
          value2: '9',
          operator: '-',
        },
      ],
    },
    {
      target: '49',
      numbers: [1, 6, 6, 7, 8],
      sampleResult: '((((6 - 1) * 7) + 6) + 8)',
      hints: [
        {
          value1: '6',
          value2: '1',
          operator: '-',
        },
        {
          value1: '5',
          value2: '7',
          operator: '*',
        },
        {
          value1: '35',
          value2: '6',
          operator: '+',
        },
      ],
    },
    {
      target: '79',
      numbers: [2, 2, 2, 9, 9],
      sampleResult: '((((2 + 9) - 2) * 9) - 2)',
      hints: [
        {
          value1: '2',
          value2: '9',
          operator: '+',
        },
        {
          value1: '11',
          value2: '2',
          operator: '-',
        },
        {
          value1: '9',
          value2: '9',
          operator: '*',
        },
      ],
    },
    {
      target: '38',
      numbers: [2, 2, 4, 8, 9],
      sampleResult: '((((2 + 4) + 9) * 2) + 8)',
      hints: [
        {
          value1: '2',
          value2: '4',
          operator: '+',
        },
        {
          value1: '6',
          value2: '9',
          operator: '+',
        },
        {
          value1: '15',
          value2: '2',
          operator: '*',
        },
      ],
    },
    {
      target: '28',
      numbers: [2, 3, 3, 5, 7],
      sampleResult: '((((3 + 7) * 2) + 3) + 5)',
      hints: [
        {
          value1: '3',
          value2: '7',
          operator: '+',
        },
        {
          value1: '10',
          value2: '2',
          operator: '*',
        },
        {
          value1: '20',
          value2: '3',
          operator: '+',
        },
      ],
    },
    {
      target: '39',
      numbers: [2, 3, 6, 6, 9],
      sampleResult: '((((3 + 6) + 6) * 2) + 9)',
      hints: [
        {
          value1: '3',
          value2: '6',
          operator: '+',
        },
        {
          value1: '9',
          value2: '6',
          operator: '+',
        },
        {
          value1: '15',
          value2: '2',
          operator: '*',
        },
      ],
    },
    {
      target: '48',
      numbers: [2, 4, 6, 7, 8],
      sampleResult: '((((2 + 8) - 4) * 7) + 6)',
      hints: [
        {
          value1: '2',
          value2: '8',
          operator: '+',
        },
        {
          value1: '10',
          value2: '4',
          operator: '-',
        },
        {
          value1: '6',
          value2: '7',
          operator: '*',
        },
      ],
    },
    {
      target: '77',
      numbers: [2, 7, 7, 7, 9],
      sampleResult: '((((2 + 7) + 9) - 7) * 7)',
      hints: [
        {
          value1: '2',
          value2: '7',
          operator: '+',
        },
        {
          value1: '9',
          value2: '9',
          operator: '+',
        },
        {
          value1: '18',
          value2: '7',
          operator: '-',
        },
      ],
    },
    {
      target: '27',
      numbers: [3, 3, 5, 6, 7],
      sampleResult: '((((3 * 3) + 5) + 6) + 7)',
      hints: [
        {
          value1: '3',
          value2: '3',
          operator: '*',
        },
        {
          value1: '9',
          value2: '5',
          operator: '+',
        },
        {
          value1: '14',
          value2: '6',
          operator: '+',
        },
      ],
    },
    {
      target: '35',
      numbers: [3, 4, 6, 6, 8],
      sampleResult: '((((4 + 6) - 6) * 8) + 3)',
      hints: [
        {
          value1: '4',
          value2: '6',
          operator: '+',
        },
        {
          value1: '10',
          value2: '6',
          operator: '-',
        },
        {
          value1: '4',
          value2: '8',
          operator: '*',
        },
      ],
    },
    {
      target: '34',
      numbers: [3, 7, 7, 7, 9],
      sampleResult: '((((3 + 7) - 7) * 9) + 7)',
      hints: [
        {
          value1: '3',
          value2: '7',
          operator: '+',
        },
        {
          value1: '10',
          value2: '7',
          operator: '-',
        },
        {
          value1: '3',
          value2: '9',
          operator: '*',
        },
      ],
    },
    {
      target: '40',
      numbers: [4, 5, 5, 7, 8],
      sampleResult: '((((4 * 5) + 5) + 7) + 8)',
      hints: [
        {
          value1: '4',
          value2: '5',
          operator: '*',
        },
        {
          value1: '20',
          value2: '5',
          operator: '+',
        },
        {
          value1: '25',
          value2: '7',
          operator: '+',
        },
      ],
    },
    {
      target: '31',
      numbers: [5, 5, 7, 7, 8],
      sampleResult: '((((5 + 5) - 7) * 8) + 7)',
      hints: [
        {
          value1: '5',
          value2: '5',
          operator: '+',
        },
        {
          value1: '10',
          value2: '7',
          operator: '-',
        },
        {
          value1: '3',
          value2: '8',
          operator: '*',
        },
      ],
    },
  ],
  3: [
    {
      target: '64',
      numbers: [1, 1, 1, 1, 3],
      sampleResult: '((((1 + 1) + 1) + 1) ^ 3)',
      hints: [
        {
          value1: '1',
          value2: '1',
          operator: '+',
        },
        {
          value1: '2',
          value2: '1',
          operator: '+',
        },
        {
          value1: '3',
          value2: '1',
          operator: '+',
        },
      ],
    },
    {
      target: '57',
      numbers: [1, 1, 5, 6, 9],
      sampleResult: '((((1 + 9) * 5) + 1) + 6)',
      hints: [
        {
          value1: '1',
          value2: '9',
          operator: '+',
        },
        {
          value1: '10',
          value2: '5',
          operator: '*',
        },
        {
          value1: '50',
          value2: '1',
          operator: '+',
        },
      ],
    },
    {
      target: '84',
      numbers: [1, 2, 3, 5, 6],
      sampleResult: '((((1 + 2) * 3) + 5) * 6)',
      hints: [
        {
          value1: '1',
          value2: '2',
          operator: '+',
        },
        {
          value1: '3',
          value2: '3',
          operator: '*',
        },
        {
          value1: '9',
          value2: '5',
          operator: '+',
        },
      ],
    },
    {
      target: '45',
      numbers: [1, 2, 6, 7, 7],
      sampleResult: '((((7 - 1) * 6) + 2) + 7)',
      hints: [
        {
          value1: '7',
          value2: '1',
          operator: '-',
        },
        {
          value1: '6',
          value2: '6',
          operator: '*',
        },
        {
          value1: '36',
          value2: '2',
          operator: '+',
        },
      ],
    },
    {
      target: '58',
      numbers: [1, 3, 4, 7, 8],
      sampleResult: '((((4 + 7) + 8) * 3) + 1)',
      hints: [
        {
          value1: '4',
          value2: '7',
          operator: '+',
        },
        {
          value1: '11',
          value2: '8',
          operator: '+',
        },
        {
          value1: '19',
          value2: '3',
          operator: '*',
        },
      ],
    },
    {
      target: '-18',
      numbers: [1, 4, 5, 5, 7],
      sampleResult: '((((1 - 5) * 4) + 5) - 7)',
      hints: [
        {
          value1: '1',
          value2: '5',
          operator: '-',
        },
        {
          value1: '-4',
          value2: '4',
          operator: '*',
        },
        {
          value1: '-16',
          value2: '5',
          operator: '+',
        },
      ],
    },
    {
      target: '46',
      numbers: [1, 5, 7, 9, 9],
      sampleResult: '((((5 - 1) * 7) + 9) + 9)',
      hints: [
        {
          value1: '5',
          value2: '1',
          operator: '-',
        },
        {
          value1: '4',
          value2: '7',
          operator: '*',
        },
        {
          value1: '28',
          value2: '9',
          operator: '+',
        },
      ],
    },
    {
      target: '-15',
      numbers: [2, 2, 3, 3, 4],
      sampleResult: '((((3 - 2) - 2) - 4) * 3)',
      hints: [
        {
          value1: '3',
          value2: '2',
          operator: '-',
        },
        {
          value1: '1',
          value2: '2',
          operator: '-',
        },
        {
          value1: '-1',
          value2: '4',
          operator: '-',
        },
      ],
    },
    {
      target: '57',
      numbers: [2, 2, 5, 5, 8],
      sampleResult: '((((2 + 8) * 5) + 2) + 5)',
      hints: [
        {
          value1: '2',
          value2: '8',
          operator: '+',
        },
        {
          value1: '10',
          value2: '5',
          operator: '*',
        },
        {
          value1: '50',
          value2: '2',
          operator: '+',
        },
      ],
    },
    {
      target: '-13',
      numbers: [2, 3, 3, 5, 8],
      sampleResult: '((((3 - 3) * 2) - 5) - 8)',
      hints: [
        {
          value1: '3',
          value2: '3',
          operator: '-',
        },
        {
          value1: '0',
          value2: '2',
          operator: '*',
        },
        {
          value1: '0',
          value2: '5',
          operator: '-',
        },
      ],
    },
    {
      target: '67',
      numbers: [2, 3, 5, 8, 9],
      sampleResult: '((((3 * 8) + 5) * 2) + 9)',
      hints: [
        {
          value1: '3',
          value2: '8',
          operator: '*',
        },
        {
          value1: '24',
          value2: '5',
          operator: '+',
        },
        {
          value1: '29',
          value2: '2',
          operator: '*',
        },
      ],
    },
    {
      target: '150',
      numbers: [2, 4, 5, 6, 8],
      sampleResult: '((((4 + 8) * 2) + 6) * 5)',
      hints: [
        {
          value1: '4',
          value2: '8',
          operator: '+',
        },
        {
          value1: '12',
          value2: '2',
          operator: '*',
        },
        {
          value1: '24',
          value2: '6',
          operator: '+',
        },
      ],
    },
    {
      target: '53',
      numbers: [2, 5, 6, 7, 9],
      sampleResult: '((((2 * 9) - 6) * 5) - 7)',
      hints: [
        {
          value1: '2',
          value2: '9',
          operator: '*',
        },
        {
          value1: '18',
          value2: '6',
          operator: '-',
        },
        {
          value1: '12',
          value2: '5',
          operator: '*',
        },
      ],
    },
    {
      target: '57',
      numbers: [3, 3, 3, 4, 4],
      sampleResult: '((((3 * 4) + 3) + 4) * 3)',
      hints: [
        {
          value1: '3',
          value2: '4',
          operator: '*',
        },
        {
          value1: '12',
          value2: '3',
          operator: '+',
        },
        {
          value1: '15',
          value2: '4',
          operator: '+',
        },
      ],
    },
    {
      target: '60',
      numbers: [3, 3, 5, 8, 8],
      sampleResult: '((((5 + 8) + 8) * 3) - 3)',
      hints: [
        {
          value1: '5',
          value2: '8',
          operator: '+',
        },
        {
          value1: '13',
          value2: '8',
          operator: '+',
        },
        {
          value1: '21',
          value2: '3',
          operator: '*',
        },
      ],
    },
    {
      target: '92',
      numbers: [3, 4, 5, 7, 9],
      sampleResult: '((((5 - 3) * 7) + 9) * 4)',
      hints: [
        {
          value1: '5',
          value2: '3',
          operator: '-',
        },
        {
          value1: '2',
          value2: '7',
          operator: '*',
        },
        {
          value1: '14',
          value2: '9',
          operator: '+',
        },
      ],
    },
    {
      target: '58',
      numbers: [3, 5, 8, 8, 9],
      sampleResult: '((((5 + 9) * 3) + 8) + 8)',
      hints: [
        {
          value1: '5',
          value2: '9',
          operator: '+',
        },
        {
          value1: '14',
          value2: '3',
          operator: '*',
        },
        {
          value1: '42',
          value2: '8',
          operator: '+',
        },
      ],
    },
    {
      target: '-11',
      numbers: [4, 4, 5, 6, 9],
      sampleResult: '((((4 + 4) - 9) * 5) - 6)',
      hints: [
        {
          value1: '4',
          value2: '4',
          operator: '+',
        },
        {
          value1: '8',
          value2: '9',
          operator: '-',
        },
        {
          value1: '-1',
          value2: '5',
          operator: '*',
        },
      ],
    },
    {
      target: '184',
      numbers: [4, 6, 6, 7, 8],
      sampleResult: '((((4 + 6) + 6) + 7) * 8)',
      hints: [
        {
          value1: '4',
          value2: '6',
          operator: '+',
        },
        {
          value1: '10',
          value2: '6',
          operator: '+',
        },
        {
          value1: '16',
          value2: '7',
          operator: '+',
        },
      ],
    },
    {
      target: '57',
      numbers: [5, 6, 6, 8, 9],
      sampleResult: '((((6 * 9) + 5) + 6) - 8)',
      hints: [
        {
          value1: '6',
          value2: '9',
          operator: '*',
        },
        {
          value1: '54',
          value2: '5',
          operator: '+',
        },
        {
          value1: '59',
          value2: '6',
          operator: '+',
        },
      ],
    },
  ],
  4: [
    {
      target: '-8',
      numbers: [1, 1, 1, 1, 3],
      sampleResult: '((((1 - 1) - 1) - 1) ^ 3)',
      hints: [
        {
          value1: '1',
          value2: '1',
          operator: '-',
        },
        {
          value1: '0',
          value2: '1',
          operator: '-',
        },
        {
          value1: '-1',
          value2: '1',
          operator: '-',
        },
      ],
    },
    {
      target: '-78',
      numbers: [1, 1, 6, 7, 8],
      sampleResult: '((((1 + 1) - 7) - 8) * 6)',
      hints: [
        {
          value1: '1',
          value2: '1',
          operator: '+',
        },
        {
          value1: '2',
          value2: '7',
          operator: '-',
        },
        {
          value1: '-5',
          value2: '8',
          operator: '-',
        },
      ],
    },
    {
      target: '244',
      numbers: [1, 2, 3, 9, 9],
      sampleResult: '((((9 * 9) + 1) * 3) - 2)',
      hints: [
        {
          value1: '9',
          value2: '9',
          operator: '*',
        },
        {
          value1: '81',
          value2: '1',
          operator: '+',
        },
        {
          value1: '82',
          value2: '3',
          operator: '*',
        },
      ],
    },
    {
      target: '348',
      numbers: [1, 3, 3, 4, 5],
      sampleResult: '((((3 + 4) * 1) ^ 3) + 5)',
      hints: [
        {
          value1: '3',
          value2: '4',
          operator: '+',
        },
        {
          value1: '7',
          value2: '1',
          operator: '*',
        },
        {
          value1: '7',
          value2: '3',
          operator: '^',
        },
      ],
    },
    {
      target: '143',
      numbers: [1, 3, 6, 6, 9],
      sampleResult: '((((6 * 9) - 6) * 3) - 1)',
      hints: [
        {
          value1: '6',
          value2: '9',
          operator: '*',
        },
        {
          value1: '54',
          value2: '6',
          operator: '-',
        },
        {
          value1: '48',
          value2: '3',
          operator: '*',
        },
      ],
    },
    {
      target: '343',
      numbers: [1, 4, 7, 9, 9],
      sampleResult: '((((1 + 9) * 4) + 9) * 7)',
      hints: [
        {
          value1: '1',
          value2: '9',
          operator: '+',
        },
        {
          value1: '10',
          value2: '4',
          operator: '*',
        },
        {
          value1: '40',
          value2: '9',
          operator: '+',
        },
      ],
    },
    {
      target: '372',
      numbers: [2, 2, 2, 3, 6],
      sampleResult: '((((2 + 2) ^ 3) - 2) * 6)',
      hints: [
        {
          value1: '2',
          value2: '2',
          operator: '+',
        },
        {
          value1: '4',
          value2: '3',
          operator: '^',
        },
        {
          value1: '64',
          value2: '2',
          operator: '-',
        },
      ],
    },
    {
      target: '293',
      numbers: [2, 2, 4, 5, 6],
      sampleResult: '((((2 * 6) + 5) ^ 2) + 4)',
      hints: [
        {
          value1: '2',
          value2: '6',
          operator: '*',
        },
        {
          value1: '12',
          value2: '5',
          operator: '+',
        },
        {
          value1: '17',
          value2: '2',
          operator: '^',
        },
      ],
    },
    {
      target: '76',
      numbers: [2, 3, 3, 3, 8],
      sampleResult: '((((3 ^ 3) + 3) + 8) * 2)',
      hints: [
        {
          value1: '3',
          value2: '3',
          operator: '^',
        },
        {
          value1: '27',
          value2: '3',
          operator: '+',
        },
        {
          value1: '30',
          value2: '8',
          operator: '+',
        },
      ],
    },
    {
      target: '125',
      numbers: [2, 3, 5, 5, 8],
      sampleResult: '((((3 * 5) + 2) + 8) * 5)',
      hints: [
        {
          value1: '3',
          value2: '5',
          operator: '*',
        },
        {
          value1: '15',
          value2: '2',
          operator: '+',
        },
        {
          value1: '17',
          value2: '8',
          operator: '+',
        },
      ],
    },
    {
      target: '142',
      numbers: [2, 4, 4, 7, 9],
      sampleResult: '((((7 * 9) + 4) + 4) * 2)',
      hints: [
        {
          value1: '7',
          value2: '9',
          operator: '*',
        },
        {
          value1: '63',
          value2: '4',
          operator: '+',
        },
        {
          value1: '67',
          value2: '4',
          operator: '+',
        },
      ],
    },
    {
      target: '-20',
      numbers: [2, 5, 5, 7, 9],
      sampleResult: '((((5 - 9) * 2) - 5) - 7)',
      hints: [
        {
          value1: '5',
          value2: '9',
          operator: '-',
        },
        {
          value1: '-4',
          value2: '2',
          operator: '*',
        },
        {
          value1: '-8',
          value2: '5',
          operator: '-',
        },
      ],
    },
    {
      target: '104',
      numbers: [2, 7, 8, 8, 9],
      sampleResult: '((((7 + 9) - 2) * 8) - 8)',
      hints: [
        {
          value1: '7',
          value2: '9',
          operator: '+',
        },
        {
          value1: '16',
          value2: '2',
          operator: '-',
        },
        {
          value1: '14',
          value2: '8',
          operator: '*',
        },
      ],
    },
    {
      target: '370',
      numbers: [3, 3, 5, 5, 9],
      sampleResult: '((((3 * 5) ^ 3) / 9) - 5)',
      hints: [
        {
          value1: '3',
          value2: '5',
          operator: '*',
        },
        {
          value1: '15',
          value2: '3',
          operator: '^',
        },
        {
          value1: '3375',
          value2: '9',
          operator: '/',
        },
      ],
    },
    {
      target: '-84',
      numbers: [3, 4, 5, 5, 8],
      sampleResult: '((((3 - 5) * 8) - 5) * 4)',
      hints: [
        {
          value1: '3',
          value2: '5',
          operator: '-',
        },
        {
          value1: '-2',
          value2: '8',
          operator: '*',
        },
        {
          value1: '-16',
          value2: '5',
          operator: '-',
        },
      ],
    },
    {
      target: '155',
      numbers: [3, 5, 5, 9, 9],
      sampleResult: '((((3 * 9) + 9) - 5) * 5)',
      hints: [
        {
          value1: '3',
          value2: '9',
          operator: '*',
        },
        {
          value1: '27',
          value2: '9',
          operator: '+',
        },
        {
          value1: '36',
          value2: '5',
          operator: '-',
        },
      ],
    },
    {
      target: '186',
      numbers: [3, 7, 8, 9, 9],
      sampleResult: '((((3 * 7) * 8) + 9) + 9)',
      hints: [
        {
          value1: '3',
          value2: '7',
          operator: '*',
        },
        {
          value1: '21',
          value2: '8',
          operator: '*',
        },
        {
          value1: '168',
          value2: '9',
          operator: '+',
        },
      ],
    },
    {
      target: '159',
      numbers: [4, 5, 5, 6, 6],
      sampleResult: '((((6 * 6) + 5) * 4) - 5)',
      hints: [
        {
          value1: '6',
          value2: '6',
          operator: '*',
        },
        {
          value1: '36',
          value2: '5',
          operator: '+',
        },
        {
          value1: '41',
          value2: '4',
          operator: '*',
        },
      ],
    },
    {
      target: '330',
      numbers: [4, 6, 9, 9, 9],
      sampleResult: '(((4 + 9) + 9) * (6 + 9))',
      hints: [
        {
          value1: '4',
          value2: '9',
          operator: '+',
        },
        {
          value1: '13',
          value2: '9',
          operator: '+',
        },
        {
          value1: '6',
          value2: '9',
          operator: '+',
        },
      ],
    },
    {
      target: '122',
      numbers: [5, 6, 7, 7, 9],
      sampleResult: '(((7 + 9) * 5) + (6 * 7))',
      hints: [
        {
          value1: '7',
          value2: '9',
          operator: '+',
        },
        {
          value1: '16',
          value2: '5',
          operator: '*',
        },
        {
          value1: '6',
          value2: '7',
          operator: '*',
        },
      ],
    },
  ],
  5: [
    {
      target: '243',
      numbers: [1, 1, 1, 2, 3],
      sampleResult: '(((1 + 1) + 1) ^ (2 + 3))',
      hints: [
        {
          value1: '1',
          value2: '1',
          operator: '+',
        },
        {
          value1: '2',
          value2: '1',
          operator: '+',
        },
        {
          value1: '2',
          value2: '3',
          operator: '+',
        },
      ],
    },
    {
      target: '-384',
      numbers: [1, 2, 2, 2, 7],
      sampleResult: '(((1 - 2) - 2) * (2 ^ 7))',
      hints: [
        {
          value1: '1',
          value2: '2',
          operator: '-',
        },
        {
          value1: '-1',
          value2: '2',
          operator: '-',
        },
        {
          value1: '2',
          value2: '7',
          operator: '^',
        },
      ],
    },
    {
      target: '-127',
      numbers: [1, 2, 3, 7, 7],
      sampleResult: '((((2 + 3) - 7) ^ 7) + 1)',
      hints: [
        {
          value1: '2',
          value2: '3',
          operator: '+',
        },
        {
          value1: '5',
          value2: '7',
          operator: '-',
        },
        {
          value1: '-2',
          value2: '7',
          operator: '^',
        },
      ],
    },
    {
      target: '-348',
      numbers: [1, 2, 6, 6, 8],
      sampleResult: '(((1 * 6) - (8 ^ 2)) * 6)',
      hints: [
        {
          value1: '1',
          value2: '6',
          operator: '*',
        },
        {
          value1: '8',
          value2: '2',
          operator: '^',
        },
        {
          value1: '6',
          value2: '64',
          operator: '-',
        },
      ],
    },
    {
      target: '-87',
      numbers: [1, 3, 4, 5, 8],
      sampleResult: '((((1 - 4) * 8) - 5) * 3)',
      hints: [
        {
          value1: '1',
          value2: '4',
          operator: '-',
        },
        {
          value1: '-3',
          value2: '8',
          operator: '*',
        },
        {
          value1: '-24',
          value2: '5',
          operator: '-',
        },
      ],
    },
    {
      target: '323',
      numbers: [1, 3, 9, 9, 9],
      sampleResult: '((((3 * 9) + 9) * 9) - 1)',
      hints: [
        {
          value1: '3',
          value2: '9',
          operator: '*',
        },
        {
          value1: '27',
          value2: '9',
          operator: '+',
        },
        {
          value1: '36',
          value2: '9',
          operator: '*',
        },
      ],
    },
    {
      target: '226',
      numbers: [1, 5, 5, 8, 9],
      sampleResult: '((((5 * 5) + 1) * 9) - 8)',
      hints: [
        {
          value1: '5',
          value2: '5',
          operator: '*',
        },
        {
          value1: '25',
          value2: '1',
          operator: '+',
        },
        {
          value1: '26',
          value2: '9',
          operator: '*',
        },
      ],
    },
    {
      target: '-126',
      numbers: [2, 2, 3, 3, 6],
      sampleResult: '(((3 / 3) - (2 ^ 6)) * 2)',
      hints: [
        {
          value1: '3',
          value2: '3',
          operator: '/',
        },
        {
          value1: '2',
          value2: '6',
          operator: '^',
        },
        {
          value1: '1',
          value2: '64',
          operator: '-',
        },
      ],
    },
    {
      target: '393',
      numbers: [2, 2, 5, 5, 7],
      sampleResult: '((((5 + 5) * 2) ^ 2) - 7)',
      hints: [
        {
          value1: '5',
          value2: '5',
          operator: '+',
        },
        {
          value1: '10',
          value2: '2',
          operator: '*',
        },
        {
          value1: '20',
          value2: '2',
          operator: '^',
        },
      ],
    },
    {
      target: '-77',
      numbers: [2, 3, 3, 5, 7],
      sampleResult: '(((3 - 5) - (3 ^ 2)) * 7)',
      hints: [
        {
          value1: '3',
          value2: '5',
          operator: '-',
        },
        {
          value1: '3',
          value2: '2',
          operator: '^',
        },
        {
          value1: '-2',
          value2: '9',
          operator: '-',
        },
      ],
    },
    {
      target: '-143',
      numbers: [2, 3, 5, 5, 6],
      sampleResult: '(((2 - 5) * 6) - (5 ^ 3))',
      hints: [
        {
          value1: '2',
          value2: '5',
          operator: '-',
        },
        {
          value1: '-3',
          value2: '6',
          operator: '*',
        },
        {
          value1: '5',
          value2: '3',
          operator: '^',
        },
      ],
    },
    {
      target: '500',
      numbers: [2, 3, 9, 9, 9],
      sampleResult: '((((9 / 9) + 9) ^ 3) / 2)',
      hints: [
        {
          value1: '9',
          value2: '9',
          operator: '/',
        },
        {
          value1: '1',
          value2: '9',
          operator: '+',
        },
        {
          value1: '10',
          value2: '3',
          operator: '^',
        },
      ],
    },
    {
      target: '-243',
      numbers: [2, 4, 7, 7, 8],
      sampleResult: '(((8 - 4) - 7) ^ (7 - 2))',
      hints: [
        {
          value1: '8',
          value2: '4',
          operator: '-',
        },
        {
          value1: '4',
          value2: '7',
          operator: '-',
        },
        {
          value1: '7',
          value2: '2',
          operator: '-',
        },
      ],
    },
    {
      target: '194',
      numbers: [2, 6, 6, 7, 7],
      sampleResult: '((((6 + 7) * 7) + 6) * 2)',
      hints: [
        {
          value1: '6',
          value2: '7',
          operator: '+',
        },
        {
          value1: '13',
          value2: '7',
          operator: '*',
        },
        {
          value1: '91',
          value2: '6',
          operator: '+',
        },
      ],
    },
    {
      target: '505',
      numbers: [3, 3, 4, 6, 7],
      sampleResult: '((((4 * 6) / 3) ^ 3) - 7)',
      hints: [
        {
          value1: '4',
          value2: '6',
          operator: '*',
        },
        {
          value1: '24',
          value2: '3',
          operator: '/',
        },
        {
          value1: '8',
          value2: '3',
          operator: '^',
        },
      ],
    },
    {
      target: '274',
      numbers: [3, 4, 4, 6, 6],
      sampleResult: '((((4 ^ 3) + 6) * 4) - 6)',
      hints: [
        {
          value1: '4',
          value2: '3',
          operator: '^',
        },
        {
          value1: '64',
          value2: '6',
          operator: '+',
        },
        {
          value1: '70',
          value2: '4',
          operator: '*',
        },
      ],
    },
    {
      target: '-124',
      numbers: [3, 4, 7, 8, 9],
      sampleResult: '((((9 - 3) - 8) ^ 7) + 4)',
      hints: [
        {
          value1: '9',
          value2: '3',
          operator: '-',
        },
        {
          value1: '6',
          value2: '8',
          operator: '-',
        },
        {
          value1: '-2',
          value2: '7',
          operator: '^',
        },
      ],
    },
    {
      target: '376',
      numbers: [3, 6, 7, 7, 9],
      sampleResult: '(((6 * 7) - 9) + (7 ^ 3))',
      hints: [
        {
          value1: '6',
          value2: '7',
          operator: '*',
        },
        {
          value1: '42',
          value2: '9',
          operator: '-',
        },
        {
          value1: '7',
          value2: '3',
          operator: '^',
        },
      ],
    },
    {
      target: '424',
      numbers: [4, 5, 5, 8, 8],
      sampleResult: '((((4 + 5) * 5) + 8) * 8)',
      hints: [
        {
          value1: '4',
          value2: '5',
          operator: '+',
        },
        {
          value1: '9',
          value2: '5',
          operator: '*',
        },
        {
          value1: '45',
          value2: '8',
          operator: '+',
        },
      ],
    },
    {
      target: '258',
      numbers: [5, 5, 6, 7, 8],
      sampleResult: '((((6 * 8) + 5) * 5) - 7)',
      hints: [
        {
          value1: '6',
          value2: '8',
          operator: '*',
        },
        {
          value1: '48',
          value2: '5',
          operator: '+',
        },
        {
          value1: '53',
          value2: '5',
          operator: '*',
        },
      ],
    },
  ],
};

export async function getRandomGame(level) {
  const levelData = games[level];
  const gameData = levelData[Math.floor(Math.random() * levelData.length)];
  return {
    ...gameData,
    numbers: shuffleArray(
      gameData.numbers.map((number) => new GameNumber(number)),
    ),
  };
}
