import React from 'react';
import {StyleSheet, Animated} from 'react-native';

export default class AnimatedMessage extends React.Component {
  constructor(props) {
    super(props);
    this.animateValue = new Animated.Value(0);
  }

  animate() {
    this.animateValue.setValue(0);
    Animated.timing(this.animateValue, {
      toValue: this.props.reverse ? 4 : 1,
      duration: this.props.reverse ? 2000 : 500,
      useNativeDriver: true,
    }).start();
  }

  componentDidMount() {
    this.animate();
  }

  render() {
    const fade = this.animateValue.interpolate({
      inputRange: this.props.reverse ? [0, 0.2, 3.8, 4] : [0, 0.2, 1],
      outputRange: this.props.reverse ? [0, 1, 1, 0] : [0, 1, 1],
    });
    const scale = this.animateValue.interpolate({
      inputRange: this.props.reverse
        ? [0, 0.5, 0.75, 1, 3.5, 4]
        : [0, 0.5, 0.75, 1],
      outputRange: this.props.reverse
        ? [0.8, 1.05, 0.98, 1, 1, 0.8]
        : [0.8, 1.05, 0.98, 1],
    });
    return (
      <Animated.View
        style={[
          styles.container,
          this.props.style,
          {opacity: fade, transform: [{scale}]},
        ]}>
        {this.props.children}
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});
