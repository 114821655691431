import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import levels from './difficultyLevels';
import {getLevelDurations} from './levelUtils';
import {registerChange, scaleW} from './scaleUtils';

export default class DifficultyRestrictions extends React.Component {
  componentDidMount() {
    registerChange(this);
  }

  componentWillUnmount() {
    registerChange(this, true);
  }

  renderLevel(level, minDuration, fontSize, levelWidth, durationWidth) {
    return (
      <View style={styles.levelRestrictions} key={level.level}>
        <View style={[styles.level, levelWidth]}>
          <Text style={[styles.text, fontSize]}>{level.symbol}</Text>
        </View>
        <View style={[styles.duration, durationWidth]}>
          {minDuration ? (
            <Text style={[styles.text, fontSize]}>{minDuration.limitName}</Text>
          ) : (
            <Text style={[styles.text, fontSize]}>Not Available</Text>
          )}
        </View>
      </View>
    );
  }

  render() {
    const {levelDurations, answerLevel} = getLevelDurations(
      this.props.correctAnswers,
    );
    if (answerLevel === 0) {
      return null;
    }
    const fontSize = {fontSize: scaleW(18, this.state)};
    const levelWidth = {width: scaleW(50)};
    const durationWidth = {width: scaleW(250)};
    return (
      <View style={styles.view}>
        <Text style={[styles.text, fontSize]}>
          With {answerLevel}+ Correct Answers in a row:
        </Text>
        {levels.map((level) =>
          this.renderLevel(
            level,
            levelDurations[level.level],
            fontSize,
            levelWidth,
            durationWidth,
          ),
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  levelRestrictions: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  level: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
  },
  duration: {
    alignItems: 'flex-start',
    width: 200,
  },
  text: {
    color: 'black',
    fontSize: 14,
    textAlign: 'center',
    textShadowOffset: {width: 1, height: 1},
    textShadowColor: '#bbbbbb',
    textShadowRadius: 1,
  },
});
