import * as math from 'mathjs';
import log from './log';

export default class GameNumber {
  constructor(sourceNumber1, operator = 'source', sourceNumber2) {
    this.sourceNumber1 = sourceNumber1;
    this.operator = operator;
    this.sourceNumber2 = sourceNumber2;
    this.value = this.calcValue().toString();
    if (this.operator !== 'source') {
      this.equation = this.displayNumberCalc();
    }
  }

  display() {
    if (this.operator === 'source') {
      return this.sourceNumber1.toString();
    }
    return `(${this.displayNumberCalc()})`;
  }

  calcValue() {
    try {
      return math.simplify(this.display()).toString();
    } catch (err) {
      return '?';
    }
  }

  displayNumberCalc() {
    return `${this.sourceNumber1.display()} ${
      this.operator
    } ${this.sourceNumber2.display()}`;
  }

  createCombined(operator, otherNumber) {
    return new GameNumber(this, operator, otherNumber);
  }

  equals(value) {
    try {
      log.debug('this.display()', this.display());
      log.debug('value', value);
      log.debug('math.simplify(this.display())', math.simplify(this.display()));
      log.debug('math.simplify(value)', math.simplify(value));
      return math.simplify(this.display()).equals(math.simplify(value));
    } catch {
      return false;
    }
  }

  static rehydrate(source) {
    if (source.operator === 'source') {
      return new GameNumber(source.sourceNumber1);
    } else {
      return new GameNumber(
        GameNumber.rehydrate(source.sourceNumber1),
        source.operator,
        GameNumber.rehydrate(source.sourceNumber2),
      );
    }
  }
}
