/*

App Landing Template

http://www.templatemo.com/tm-474-app-landing

*/
/*global jQuery, $, history, PopStateEvent*/

function checkScrollPosition() {
  if ($(window).scrollTop() > 50 || window.location.pathname === '/game') {
    $('.fixed-header').addClass('scroll');
  } else {
    $('.fixed-header').removeClass('scroll');
  }
}

function documentReady() {
  // jQuery.templatemo_is_chrome
  $.templatemo_is_chrome = /chrom(e|ium)/.test(
    navigator.userAgent.toLowerCase(),
  );
  $.templatemo_is_ie =
    !!navigator.userAgent.match(/Trident/g) ||
    !!navigator.userAgent.match(/MSIE/g);
  // Single page nav
  $('.fixed-header').singlePageNav({
    offset: 59,
    filter: ':not(.external)',
    // updateHash: true
  });

  checkScrollPosition();

  // nav bar
  $('.navbar-toggle').click(function () {
    $('.main-menu').toggleClass('show');
  });

  $('.main-menu a').click(function () {
    $('.main-menu').removeClass('show');
  });

  $('a.update_state').on('click', function (e) {
    let url = $(this).attr('href');
    if (url[0] === '#') {
      url = '/' + url;
    }
    history.pushState(null, null, url);
    window.dispatchEvent(new PopStateEvent('popstate'));
    checkScrollPosition();
    return false;
  });
  window.addEventListener('popstate', function () {
    if (window.location.pathname === '/game') {
      $('body').addClass('showing-game');
    } else {
      $('body').removeClass('showing-game');
    }
  });
  window.dispatchEvent(new PopStateEvent('popstate'));
  // Menu scroll to.
  $('a.scroll_effect').on('click', function (e) {
    const target_element = $(this).attr('href').replace(/^.*\//, '');
    const offset = $(target_element).offset();
    const scroll_to = offset ? offset.top : 0;
    checkScrollPosition();
    if ($(window).scrollTop() != scroll_to && target_element !== undefined) {
      // Chrome scroll to calculation and other browser scroll to calculation is different.
      let body_scroll_target;
      if ($.templatemo_is_chrome) {
        body_scroll_target = scroll_to;
      } else {
        body_scroll_target = $('body').scrollTop() + scroll_to;
      }
      $('html,body').animate({scrollTop: body_scroll_target}, 1000);
    }
    // If menu is visible hide the nav.
    // $('nav:visible').templateMoMenuHide();
    return false;
  });
  // Javascript parallax effect config for different browser.
  // Chrome browser setting
  if ($.templatemo_is_chrome) {
    $('html').attr('style', 'overflow:auto;');
    $('body').attr('style', 'overflow:auto;');
    $('body').addClass('auto-height-no-game');
    $('#templatemo_home').parallax('50%', 0.1);
    $('#templatemo_download').parallax('50%', 0.1);
    // Non IE browser setting
  } else if (!$.templatemo_is_ie) {
    $('html').attr('style', 'overflow: auto;');
    $('body').attr('style', 'background: #455a64;overflow: auto;');
    $('body').addClass('auto-height-no-game');
    $('#templatemo_home').parallax('50%', 0.1);
    $('#templatemo_download').parallax('50%', 0.1);
    // IE browser setting
  } else {
    $('#templatemo_home').parallax('50%', 0.5);
    $('#templatemo_download').parallax('50%', 0.5);
  }
}

jQuery(document).ready(function () {
  if ($('#replace-contents').length) {
    if (!$('#replace-contents div').length) {
      $('#replace-contents').load('/', null, documentReady);
    }
  } else {
    documentReady();
  }
});

$(window).on('scroll', function () {
  checkScrollPosition();
});
