import React from 'react';
import {
  Animated,
  View,
  TouchableOpacity,
  Easing,
  Image,
  StyleSheet,
} from 'react-native';
import WalkthroughTooltip from './WalkthroughTooltip';

export default class UndoButton extends React.Component {
  constructor(props) {
    super(props);
    this.spinValue = new Animated.Value(0);
  }

  spin() {
    this.spinValue.setValue(0);
    Animated.timing(this.spinValue, {
      toValue: 1,
      duration: 500,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  }

  onPress() {
    this.spin();
    this.props.onPress();
  }

  render() {
    const spin = this.spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['360deg', '0deg'],
    });
    return (
      <View>
        <WalkthroughTooltip wid="undo">
          <TouchableOpacity style={styles.undo} onPress={() => this.onPress()}>
            <Animated.View style={[styles.undo, {transform: [{rotate: spin}]}]}>
              <Image
                source={require('./assets/undo.png')}
                style={styles.undo}
              />
            </Animated.View>
          </TouchableOpacity>
        </WalkthroughTooltip>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  undo: {
    width: 52,
    height: 50,
  },
});
