import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {itemDetails} from './itemUtils';
import {registerChange, scaleW} from './scaleUtils';

const CircleDiameter = 30;

export default class ItemCount extends React.Component {
  constructor(props) {
    super(props);
    this.refreshTimer = null;
  }

  componentDidMount() {
    registerChange(this);
  }

  componentWillUnmount() {
    registerChange(this, true);
    clearTimeout(this.refreshTimer);
  }

  renderTimeout(timeout, isTimed) {
    if (!isTimed) {
      return null;
    }
    if (timeout > 0) {
      const totalSeconds = Math.floor(timeout / 1000);
      const seconds = totalSeconds % 60;
      const minutes = Math.round((totalSeconds - seconds) / 60);

      return (
        <Text
          style={[
            styles.text,
            {fontSize: scaleW(22, this.state)},
          ]}>{`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}</Text>
      );
    } else {
      return (
        <Text style={[styles.text, {fontSize: scaleW(22, this.state)}]}>
          Full
        </Text>
      );
    }
  }

  render() {
    const {count, timeout, isTimed} = itemDetails(this.props.item);
    clearTimeout(this.refreshTimer);
    if (isTimed && timeout > 0) {
      this.refreshTimer = setTimeout(() => this.forceUpdate(), 1000);
    }
    const diameter = scaleW(CircleDiameter, this.state);
    let baseFontSize = 22;
    if (count > 999) {
      baseFontSize = 11;
    } else if (count > 99) {
      baseFontSize = 15;
    }
    return (
      <View style={styles.view}>
        <View
          style={[
            styles.circle,
            {
              width: diameter,
              height: diameter,
              borderRadius: diameter / 2,
            },
          ]}>
          <Text
            style={[
              styles.countText,
              {fontSize: scaleW(baseFontSize, this.state)},
            ]}>
            {count}
          </Text>
        </View>
        {this.renderTimeout(timeout, isTimed)}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    width: 335,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 2,
    flexDirection: 'row',
  },
  circle: {
    backgroundColor: 'red',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 22,
    textAlign: 'center',
    flex: 1,
  },
  countText: {
    color: 'white',
    fontSize: 22,
    textAlign: 'center',
  },
});
