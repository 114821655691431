import {combineReducers} from 'redux';
import gameState from './gameState';
import player from './player';
import nav from './nav';

export default combineReducers({
  gameState,
  player,
  nav,
});
