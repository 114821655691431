import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import AwesomeButton from 'react-native-really-awesome-button';
import LinearGradient from 'react-native-linear-gradient';
import {itemEmpty} from './itemUtils';
import ItemCount from './ItemCount';
import {registerChange, scaleHW, scaleMin, scaleW, scaleH} from './scaleUtils';
import isWeb from './isWeb';

export default class ActionButton extends React.Component {
  componentDidMount() {
    registerChange(this);
  }

  componentWillUnmount() {
    registerChange(this, true);
  }

  onPress() {
    if (
      !this.props.isWeb &&
      this.props.item &&
      this.props.onPressItemEmpty &&
      itemEmpty(this.props.item)
    ) {
      this.props.onPressItemEmpty();
    } else {
      this.props.onPress();
    }
  }

  renderItem() {
    if (isWeb || !this.props.item) {
      return null;
    }
    return <ItemCount item={this.props.item} />;
  }

  gradientColours() {
    if (this.props.isSelected) {
      return ['#F8F9FA', '#3B824C', '#43944E'];
    }
    return ['#819BCB', '#536F9D', '#536F9D'];
  }

  typeStyles(name) {
    if (typeStyles[this.props.type][name]) {
      return typeStyles[this.props.type][name](this.state);
    }
    return null;
  }

  render() {
    const {width, height} = size[this.props.type](this.state);

    return (
      <AwesomeButton
        borderRadius={scaleH(15, this.state)}
        height={height}
        width={width}
        backgroundDarker="#202020"
        springRelease={false}
        raiseLevel={3}
        onPress={() => this.onPress()}
        style={[styles.button, this.typeStyles('button')]}>
        <LinearGradient
          width="100%"
          height="100%"
          style={styles.innerView}
          colors={this.gradientColours()}>
          <View style={[styles.innerView, this.typeStyles('innerView')]}>
            <Text style={[styles.text, this.typeStyles('text')]}>
              {this.props.text}
            </Text>
            {this.renderItem()}
          </View>
        </LinearGradient>
      </AwesomeButton>
    );
  }
}

ActionButton.defaultProps = {
  type: 'default',
};

const size = {
  SQUAT: (state) => scaleHW(80, 130, state),
  NARROW: (state) => scaleHW(55, 180, state),
  default: (state) => scaleHW(55, 400, state),
};

const styles = StyleSheet.create({
  button: {},
  innerView: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  image: {
    width: '100%',
    height: '100%',
    flex: 1,
  },
  touchable: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  text: {
    fontFamily: 'Chewy-Regular',
    color: 'white',
    fontSize: 36,
    textAlign: 'center',
    flex: 6,
    textShadowOffset: {width: 1, height: 1},
    textShadowColor: 'black',
    textShadowRadius: 1,
  },
});

const typeStyles = {
  SQUAT: {
    text: (state) => ({
      fontSize: scaleMin(26, state),
    }),
    button: (state) => {
      const margin = scaleW(10, state);
      return {
        marginRight: margin,
        marginLeft: margin,
      };
    },
  },
  NARROW: {
    text: (state) => ({
      fontSize: scaleMin(36, state),
    }),
  },
  default: {
    text: (state) => ({
      fontSize: scaleMin(36, state),
    }),
  },
};
