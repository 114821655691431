function checkIncreasedProgress(acheivementState, increment = 1) {
  const progress =
    (isNaN(acheivementState.progress) ? 0 : acheivementState.progress) +
    increment;
  return {
    progress,
    completed: progress >= this.progressGoal,
  };
}

function checkCorrectAnswer(
  extraCheck,
  acheivementState,
  finalNumber,
  difficultyLevel,
  duration,
  elapsed,
) {
  if (!acheivementState) {
    acheivementState = {
      progress: 0,
      completed: false,
    };
  }
  if (
    !acheivementState.completed &&
    finalNumber &&
    (!extraCheck ||
      extraCheck(
        acheivementState,
        finalNumber,
        difficultyLevel,
        duration,
        elapsed,
      ))
  ) {
    return {
      ...acheivementState,
      ...checkIncreasedProgress.call(this, acheivementState),
    };
  }
  return acheivementState;
}

function checkCorrectAnswerInARow(
  extraCheck,
  acheivementState,
  finalNumber,
  difficultyLevel,
  duration,
  elapsed,
) {
  if (
    acheivementState &&
    !acheivementState.completed &&
    acheivementState.progress &&
    (!finalNumber ||
      (extraCheck &&
        !extraCheck(
          acheivementState,
          finalNumber,
          difficultyLevel,
          duration,
          elapsed,
        )))
  ) {
    return {
      ...acheivementState,
      progress: 0,
    };
  }
  return checkCorrectAnswer.call(
    this,
    extraCheck,
    acheivementState,
    finalNumber,
    difficultyLevel,
    duration,
    elapsed,
  );
}

function answerCheck(extraCheck) {
  return function (
    acheivementState,
    finalNumber,
    difficultyLevel,
    duration,
    elapsed,
  ) {
    return checkCorrectAnswer.call(
      this,
      extraCheck,
      acheivementState,
      finalNumber,
      difficultyLevel,
      duration,
      elapsed,
    );
  };
}

function answerCheckInARow(extraCheck) {
  return function (
    acheivementState,
    finalNumber,
    difficultyLevel,
    duration,
    elapsed,
  ) {
    return checkCorrectAnswerInARow.call(
      this,
      extraCheck,
      acheivementState,
      finalNumber,
      difficultyLevel,
      duration,
      elapsed,
    );
  };
}

function checkDuration(time) {
  return (acheivementState, finalNumber, difficultyLevel, duration, elapsed) =>
    elapsed < time * 1000;
}

function checkLevel(level) {
  return (acheivementState, finalNumber, difficultyLevel, duration, elapsed) =>
    difficultyLevel === level;
}

// function combine(f1, f2) {
//   return (acheivementState, finalNumber, difficultyLevel, duration, elapsed) =>
//     f1(acheivementState, finalNumber, difficultyLevel, duration, elapsed) &&
//     f2(acheivementState, finalNumber, difficultyLevel, duration, elapsed);
// }

function checkOperators(operators) {
  return (acheivementState, finalNumber, difficultyLevel, duration, elapsed) =>
    operators.every(
      (opReq) =>
        (finalNumber.equation.match(new RegExp(`\\${opReq.op}`, 'g')) || [])
          .length >= opReq.min,
    );
}

export default [
  {
    name: '10-questions',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 10 questions',
    progressGoal: 10,
    checkUpdate: answerCheck(),
  },
  {
    name: '100-questions',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 100 questions',
    progressGoal: 100,
    checkUpdate: answerCheck(),
  },
  {
    name: '1000-questions',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 1000 questions',
    progressGoal: 1000,
    checkUpdate: answerCheck(),
  },
  {
    name: '10000-questions',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 10000 questions',
    progressGoal: 10000,
    checkUpdate: answerCheck(),
  },
  {
    name: '1-question-20s',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer a question in under 20s',
    progressGoal: 1,
    checkUpdate: answerCheck(checkDuration(20)),
  },
  {
    name: '10-level-1-in-a-row',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 10 easy questions in a row',
    progressGoal: 10,
    checkUpdate: answerCheckInARow(checkLevel(1)),
  },
  {
    name: '100-level-1',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 100 easy questions',
    progressGoal: 100,
    checkUpdate: answerCheck(checkLevel(1)),
  },
  {
    name: '10-level-2-in-a-row',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 10 medium questions in a row',
    progressGoal: 10,
    checkUpdate: answerCheckInARow(checkLevel(2)),
  },
  {
    name: '100-level-2',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 100 medium questions',
    progressGoal: 100,
    checkUpdate: answerCheck(checkLevel(2)),
  },
  {
    name: '10-level-3-in-a-row',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 10 hard questions in a row',
    progressGoal: 10,
    checkUpdate: answerCheckInARow(checkLevel(3)),
  },
  {
    name: '100-level-3',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 100 hard questions',
    progressGoal: 100,
    checkUpdate: answerCheck(checkLevel(3)),
  },
  {
    name: '10-level-4-in-a-row',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 10 hard² questions in a row',
    progressGoal: 10,
    checkUpdate: answerCheckInARow(checkLevel(4)),
  },
  {
    name: '100-level-4',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 100 hard² questions',
    progressGoal: 100,
    checkUpdate: answerCheck(checkLevel(4)),
  },
  {
    name: '10-level-5-in-a-row',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 10 impossible questions in a row',
    progressGoal: 10,
    checkUpdate: answerCheckInARow(checkLevel(5)),
  },
  {
    name: '100-level-5',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Answer 100 impossible questions',
    progressGoal: 100,
    checkUpdate: answerCheck(checkLevel(5)),
  },
  {
    name: 'use-2-x',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Use × twice in an answer',
    progressGoal: 1,
    checkUpdate: answerCheck(checkOperators([{op: '*', min: 2}])),
  },
  {
    name: 'use-3-x',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Use × three times in an answer',
    progressGoal: 1,
    checkUpdate: answerCheck(checkOperators([{op: '*', min: 3}])),
  },
  {
    name: 'use-4-x',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Use × four times in an answer',
    progressGoal: 1,
    checkUpdate: answerCheck(checkOperators([{op: '*', min: 4}])),
  },
  {
    name: 'use-2-div',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Use ÷ twice in an answer',
    progressGoal: 1,
    checkUpdate: answerCheck(checkOperators([{op: '/', min: 2}])),
  },
  {
    name: 'use-3-div',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Use ÷ three times in an answer',
    progressGoal: 1,
    checkUpdate: answerCheck(checkOperators([{op: '/', min: 3}])),
  },
  {
    name: 'use-4-div',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Use ÷ four times in an answer',
    progressGoal: 1,
    checkUpdate: answerCheck(checkOperators([{op: '/', min: 4}])),
  },
  {
    name: 'use-2-xy',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Use xʸ twice in an answer',
    progressGoal: 1,
    checkUpdate: answerCheck(checkOperators([{op: '^', min: 2}])),
  },
  {
    name: 'use-3-xy',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Use xʸ three times in an answer',
    progressGoal: 1,
    checkUpdate: answerCheck(checkOperators([{op: '^', min: 3}])),
  },
  {
    name: 'use-4-xy',
    icon: require('./assets/achievements/achievement.png'),
    iconSil: require('./assets/achievements/achievement-sil.png'),
    title: 'Use xʸ four times in an answer',
    progressGoal: 1,
    checkUpdate: answerCheck(checkOperators([{op: '^', min: 4}])),
  },
];
