export const MAX_DAILY = 50;

export function availableCoins(player) {
  let totalCoins = player.numberOfCoinsCollected - player.numberOfCoinsUsed;
  Object.values(player.coinsEarned || {}).forEach((earned) => {
    totalCoins += Math.min(earned, MAX_DAILY);
  });
  return totalCoins;
}

export function coinEarnedDate() {
  return new Date().toLocaleDateString('en-ZA').replace(/[^\d]/g, '');
}

export function dateCoins(player, date) {
  return Math.min(
    (player.coinsEarned && player.coinsEarned[date]) || 0,
    MAX_DAILY,
  );
}

export function todaysCoins(player) {
  return dateCoins(player, coinEarnedDate());
}
