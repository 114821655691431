import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import AwesomeButton from 'react-native-really-awesome-button';
import LinearGradient from 'react-native-linear-gradient';
import WalkthroughTooltip from './WalkthroughTooltip';
import {registerChange, scaleHW, scaleMin} from './scaleUtils';

let operatorMap;

export default class OperatorButton extends React.Component {
  componentDidMount() {
    registerChange(this);
  }

  componentWillUnmount() {
    registerChange(this, true);
  }

  renderOperator() {
    if (!operatorMap) {
      const textStyles = () => [
        styles.text,
        {fontSize: scaleMin(52, this.state)},
      ];
      operatorMap = {
        '+': () => <Text style={textStyles()}>+</Text>,
        '-': () => <Text style={textStyles()}>-</Text>,
        '*': () => <Text style={textStyles()}>×</Text>,
        '/': () => <Text style={textStyles()}>÷</Text>,
        '^': () => (
          <View style={styles.operatorView}>
            <Text style={textStyles()}>x</Text>
            <Text
              style={[
                textStyles(),
                {
                  fontSize: scaleMin(24, this.state),
                  lineHeight: scaleMin(34, this.state),
                },
              ]}>
              y
            </Text>
          </View>
        ),
      };
    }
    return operatorMap[this.props.operator]();
  }

  gradientColours() {
    if (this.props.isSelected) {
      return ['#FAF9FC', '#3B824C', '#43944E'];
    }
    return ['#F7F7F7', '#8B2EF5', '#8B2EF5'];
  }

  render() {
    return (
      <View style={[styles.view, scaleHW(108, 135, this.state)]}>
        <WalkthroughTooltip wid={`operator-${this.props.operator}`}>
          <AwesomeButton
            borderRadius={15}
            {...scaleHW(80, 135, this.state)}
            raiseLevel={3}
            backgroundDarker="#202020"
            springRelease={false}
            onPress={() => this.props.onPress()}>
            <LinearGradient
              width="100%"
              height="100%"
              style={styles.innerView}
              colors={this.gradientColours()}>
              <View style={styles.innerView}>{this.renderOperator()}</View>
            </LinearGradient>
          </AwesomeButton>
        </WalkthroughTooltip>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    width: 135,
    height: 108,
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerView: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  operatorView: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  text: {
    fontFamily: 'Chewy-Regular',
    color: 'white',
    fontSize: 36,
    textAlign: 'center',
    // fontWeight: 'bold',
    textShadowOffset: {width: 1, height: 1},
    textShadowColor: 'black',
    textShadowRadius: 1,
  },
});
