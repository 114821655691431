module.exports = [
  {
    level: 1,
    points: 0,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 2,
    points: 20,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 3,
    points: 40,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 4,
    points: 70,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 5,
    points: 100,
    rewardHints: 20,
    rewardAnswers: 10,
  },
  {
    level: 6,
    points: 150,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 7,
    points: 210,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 8,
    points: 280,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 9,
    points: 360,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 10,
    points: 500,
    rewardHints: 20,
    rewardAnswers: 10,
  },
  {
    level: 11,
    points: 650,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 12,
    points: 820,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 13,
    points: 1000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 14,
    points: 1200,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 15,
    points: 1450,
    rewardHints: 20,
    rewardAnswers: 10,
  },
  {
    level: 16,
    points: 1650,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 17,
    points: 1900,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 18,
    points: 2300,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 19,
    points: 2800,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 20,
    points: 3500,
    rewardHints: 40,
    rewardAnswers: 20,
  },
  {
    level: 21,
    points: 4400,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 22,
    points: 6500,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 23,
    points: 7700,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 24,
    points: 9000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 25,
    points: 10500,
    rewardHints: 20,
    rewardAnswers: 20,
  },
  {
    level: 26,
    points: 12200,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 27,
    points: 14000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 28,
    points: 16000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 29,
    points: 18000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 30,
    points: 20000,
    rewardHints: 40,
    rewardAnswers: 20,
  },
  {
    level: 31,
    points: 23000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 32,
    points: 26000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 33,
    points: 30000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 34,
    points: 35000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 35,
    points: 41000,
    rewardHints: 20,
    rewardAnswers: 20,
  },
  {
    level: 36,
    points: 48000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 37,
    points: 56000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 38,
    points: 55000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 39,
    points: 65000,
    rewardHints: 5,
    rewardAnswers: 2,
  },
  {
    level: 40,
    points: 70000,
    rewardHints: 40,
    rewardAnswers: 20,
  },
];
