import {REHYDRATE} from 'redux-persist';
import GameNumber from '../GameNumber';
import {lastException} from '../exceptionHandler';
import log from '../log';

const INITIAL_STATE = {
  target: 0,
  level: 1,
  stack: [],
  currentNumbers: [],
  initialNumbers: [],
  hints: [],
  initialHints: [],
  isCorrectAnswer: false,
  didGiveUp: false,
  didTimeOut: false,
  didShowAnswer: false,
  selectedIndex1: undefined,
  selectedIndex2: undefined,
  selectedOperator: undefined,
  softSelectedIndex1: undefined,
  gameStart: undefined,
  gameDuration: undefined,
  inGame: false,
  isShowingGiveUpConfirm: false,
  isShowingDifficultyRestrictions: false,
  walkthrough: null,
  solveGame: null,
};

function checkSelections(state, isHint = false) {
  if (
    typeof state.selectedIndex1 !== 'undefined' &&
    typeof state.selectedIndex2 !== 'undefined' &&
    typeof state.selectedOperator !== 'undefined'
  ) {
    log.info('state.currentNumbers', state.currentNumbers);
    log.info('state.selectedIndex1', state.selectedIndex1);
    const newNumber = state.currentNumbers[state.selectedIndex1].createCombined(
      state.selectedOperator,
      state.currentNumbers[state.selectedIndex2],
    );
    const replaceIndexes =
      state.selectedIndex1 < state.selectedIndex2
        ? [state.selectedIndex1, state.selectedIndex2]
        : [state.selectedIndex2, state.selectedIndex1];
    const newNumbers = state.currentNumbers.slice();
    newNumbers.splice(replaceIndexes[0], 1, newNumber);
    newNumbers.splice(replaceIndexes[1], 1);
    if (!isHint) {
      state.stack = state.stack.concat([state.currentNumbers]);
    }
    state.currentNumbers = newNumbers;
    state.selectedIndex1 = undefined;
    state.selectedIndex2 = undefined;
    state.selectedOperator = undefined;
    state.softSelectedIndex1 = replaceIndexes[0];
  }
  return state;
}

function rehydrateNumbers(numbers) {
  return numbers.map(GameNumber.rehydrate);
}

function getActiveState(state) {
  if (state.solveGame) {
    return state.solveGame;
  }
  if (state.walkthrough) {
    return state.walkthrough;
  }
  return state;
}

function fullState(state, activeState, extraState = {}) {
  if (state.solveGame) {
    return {
      ...state,
      solveGame: {
        ...activeState,
        ...extraState,
      },
    };
  }
  if (state.walkthrough) {
    return {
      ...state,
      walkthrough: {
        ...activeState,
        ...extraState,
      },
    };
  }
  return activeState;
}

export default (state = INITIAL_STATE, action) => {
  const activeState = getActiveState(state);
  switch (action.type) {
    case 'RESET':
      if (
        !action.payload.didLevelUp &&
        !state.walkthrough &&
        !state.solveGame &&
        (state.didShowAnswer || state.didGiveUp || state.isCorrectAnswer)
      ) {
        return {
          ...INITIAL_STATE,
          level: state.level,
          points: state.points,
          gameDuration: state.gameDuration,
          inGame: state.inGame,
        };
      }
      return state;
    case 'NEW_GAME': {
      const gameData = action.payload.gameData;
      return fullState(
        state,
        {
          ...INITIAL_STATE,
          target: gameData.target,
          currentNumbers: gameData.numbers,
          initialNumbers: gameData.numbers,
          hints: gameData.hints,
          initialHints: gameData.hints,
          sampleResult: gameData.sampleResult,
          level: activeState.level,
          points: activeState.points,
          gameStart: action.payload.now,
          gameDuration: activeState.gameDuration,
          inGame: true,
        },
        {currentStep: activeState.currentStep},
      );
    }
    case 'SELECT_NUMBER': {
      const index = action.payload;
      if (index === activeState.selectedIndex1) {
        return fullState(state, {...activeState, selectedIndex1: undefined});
      } else if (index === activeState.selectedIndex1) {
        return fullState(state, {...activeState, selectedIndex1: undefined});
      } else if (index === activeState.selectedIndex2) {
        return fullState(state, {...activeState, selectedIndex2: undefined});
      } else if (typeof activeState.selectedIndex1 === 'undefined') {
        return fullState(
          state,
          checkSelections({
            ...activeState,
            selectedIndex1: index,
            softSelectedIndex1: undefined,
          }),
        );
      } else {
        return fullState(
          state,
          checkSelections({...activeState, selectedIndex2: index}),
        );
      }
    }
    case 'SELECT_OPERATOR': {
      const operator = action.payload;
      if (operator === activeState.selectedOperator) {
        return fullState(state, {...activeState, selectedOperator: undefined});
      } else if (typeof activeState.softSelectedIndex1 !== 'undefined') {
        return fullState(state, {
          ...activeState,
          selectedOperator: operator,
          selectedIndex1: activeState.softSelectedIndex1,
          softSelectedIndex1: undefined,
        });
      } else {
        return fullState(
          state,
          checkSelections({...activeState, selectedOperator: operator}),
        );
      }
    }
    case 'UNDO':
      return fullState(state, {
        ...activeState,
        selectedIndex1: undefined,
        selectedIndex2: undefined,
        selectedOperator: undefined,
        softSelectedIndex1: undefined,
        stack: activeState.stack.slice(0, activeState.stack.length - 1),
        currentNumbers: activeState.stack[activeState.stack.length - 1],
      });
    case 'USE_HINT': {
      const sourceNumbers = activeState.stack[0] || activeState.currentNumbers;
      const selectedIndex1 = sourceNumbers.findIndex((number) =>
        number.equals(activeState.hints[0].value1),
      );
      const selectedIndex2 = sourceNumbers.findIndex(
        (number, index) =>
          index !== selectedIndex1 &&
          number.equals(activeState.hints[0].value2),
      );
      log.info('selectedIndex1', selectedIndex1);
      log.info('sourceNumbers', sourceNumbers);
      log.info('activeState.hints[0]', activeState.hints[0]);
      return fullState(
        state,
        checkSelections(
          {
            ...activeState,
            hints: activeState.hints.slice(1),
            selectedIndex1,
            selectedIndex2,
            selectedOperator: activeState.hints[0].operator,
            softSelectedIndex1: undefined,
            stack: [],
            currentNumbers: sourceNumbers,
          },
          true,
        ),
      );
    }
    case 'SHOW_GIVE_UP_CONFIRM':
      return fullState(state, {
        ...activeState,
        isShowingGiveUpConfirm: true,
      });
    case 'CANCEL_GIVE_UP_CONFIRM':
      return fullState(state, {
        ...activeState,
        isShowingGiveUpConfirm: false,
      });
    case 'GIVE_UP':
      return fullState(state, {
        ...activeState,
        didGiveUp: true,
        inGame: false,
        isShowingGiveUpConfirm: false,
        level: INITIAL_STATE.level,
        gameDuration: INITIAL_STATE.gameDuration,
      });
    case 'TIME_UP':
      return fullState(state, {
        ...activeState,
        didTimeOut: true,
        inGame: false,
        isShowingGiveUpConfirm: false,
        level: INITIAL_STATE.level,
        gameDuration: INITIAL_STATE.gameDuration,
      });
    case 'SET_LEVEL':
      return fullState(state, {
        ...activeState,
        level: action.payload,
      });
    case 'SET_GAME_DURATION':
      return fullState(state, {
        ...activeState,
        gameDuration: action.payload,
      });
    case 'GAME_WON':
      return fullState(state, {
        ...activeState,
        ...(action.payload.didShowAnswer
          ? {didShowAnswer: true}
          : {isCorrectAnswer: true}),
      });
    case 'SHOW_DIFFICULTY_RESTRICTIONS':
      return fullState(state, {
        ...activeState,
        isShowingDifficultyRestrictions: true,
      });
    case 'CLEAR_DIFFICULTY_RESTRICTIONS':
      return fullState(state, {
        ...activeState,
        isShowingDifficultyRestrictions: false,
      });
    case 'START_WALKTHROUGH':
      return {
        ...state,
        walkthrough: {...INITIAL_STATE, currentStep: 0},
      };
    case 'END_WALKTHROUGH':
      return {
        ...state,
        walkthrough: null,
      };
    case 'STASH_WALKTHROUGH':
      if (state.walkthrough) {
        return {
          ...state,
          saveWalkthrough: state.walkthrough,
          walkthrough: null,
        };
      }
      return state;
    case 'STASH_POP_WALKTHROUGH':
      if (state.saveWalkthrough) {
        return {
          ...state,
          saveWalkthrough: null,
          walkthrough: state.saveWalkthrough,
        };
      }
      return state;
    case 'CLEAR_PROGRESS_WALKTHROUGH':
      return {
        ...state,
        walkthrough: state.walkthrough && {
          ...state.walkthrough,
          clearingModal: true,
        },
      };
    case 'PROGRESS_WALKTHROUGH':
      return {
        ...state,
        walkthrough: state.walkthrough && {
          ...state.walkthrough,
          clearingModal: false,
          currentStep: state.walkthrough.currentStep + 1,
        },
      };
    case 'ENABLE_WALKTHROUGH':
      return {
        ...state,
        walkthrough: state.walkthrough && {
          ...state.walkthrough,
          clearingModal: false,
        },
      };
    case 'START_SOLVE_GAME':
      return {
        ...state,
        solveGame: {
          ...INITIAL_STATE,
          target: action.payload.target,
          currentNumbers: action.payload.numbers,
          initialNumbers: action.payload.numbers,
          hints: action.payload.hints || [],
          initialHints: action.payload.hints || [],
          sampleResult: action.payload.sampleResult || '',
          gameStart: action.payload.now,
          inGame: true,
        },
      };
    case 'END_SOLVE_GAME':
      return {
        ...state,
        solveGame: null,
      };
    case 'RESET_GAME':
      return fullState(state, {
        ...INITIAL_STATE,
        target: activeState.target,
        currentNumbers: activeState.initialNumbers,
        initialNumbers: activeState.initialNumbers,
        hints: activeState.initialHints,
        initialHints: activeState.initialHints,
        sampleResult: activeState.sampleResult,
        gameStart: action.payload.now,
        inGame: true,
      });
    case REHYDRATE:
      if (action.payload && action.payload.gameState) {
        log.info('got rehydrate');
        // return { ...state };
        const gameState = {
          ...action.payload.gameState,
          walkthrough: null,
          solveGame: null,
        };
        switch (lastException()) {
          case 'FIRST':
            if (
              gameState.currentNumbers &&
              gameState.currentNumbers.length > 1 &&
              gameState.initialNumbers &&
              gameState.initialNumbers.length > 1
            ) {
              return {
                ...INITIAL_STATE,
                target: gameState.target,
                currentNumbers: rehydrateNumbers(gameState.initialNumbers),
                initialNumbers: rehydrateNumbers(gameState.initialNumbers),
                stack: [],
              };
            } else {
              return INITIAL_STATE;
            }
          case 'MULTIPLE':
            return INITIAL_STATE;
          default:
            return {
              ...gameState,
              currentNumbers: rehydrateNumbers(gameState.currentNumbers),
              initialNumbers: gameState.initialNumbers
                ? rehydrateNumbers(gameState.initialNumbers)
                : null,
              stack: gameState.stack.map((turn) => rehydrateNumbers(turn)),
            };
        }
      }
      return state;
    default:
      return state;
  }
};
