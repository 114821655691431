export default [
  {
    level: 1,
    points: 1,
    name: 'Easy',
    symbol: '+',
  },
  {
    level: 2,
    points: 2,
    name: 'Medium',
    symbol: '×',
  },
  {
    level: 3,
    points: 3,
    name: 'Hard',
    symbol: '÷',
  },
  {
    level: 4,
    points: 5,
    name: 'Hard²',
    symbol: '√',
  },
  {
    level: 5,
    points: 8,
    name: 'Impossible',
    symbol: '∞',
  },
];
