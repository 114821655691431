import {Dimensions} from 'react-native';

const defaultWindowHeight = 896;
const defaultWindowWidth = 414;

const componentsWatching = [];

function dimensionsChanged(dimensions) {
  const {width: dimensionsWidth, height: dimensionHeight} = dimensions.window;
  componentsWatching.forEach((component) =>
    component.setState({dimensionsWidth, dimensionHeight}),
  );
}

export function registerChange(component, isRemoval = false) {
  if (isRemoval) {
    const index = componentsWatching.indexOf(component);
    if (index >= 0) {
      componentsWatching.slice(index, 1);
      if (componentsWatching.length === 0) {
        Dimensions.removeEventListener('change', dimensionsChanged);
      }
    }
  } else {
    if (componentsWatching.length === 0) {
      Dimensions.addEventListener('change', dimensionsChanged);
    }
    if (componentsWatching.indexOf(component) < 0) {
      componentsWatching.push(component);
    }
    const {width: dimensionsWidth, height: dimensionHeight} = Dimensions.get(
      'window',
    );
    component.setState({dimensionsWidth, dimensionHeight});
  }
}

function doScaleHeight(dimensions, standardHeight) {
  let scaledHeight = standardHeight;
  if (dimensions.height < defaultWindowHeight) {
    scaledHeight = Math.floor(
      (standardHeight * dimensions.height) / defaultWindowHeight,
    );
  }
  return scaledHeight;
}

function doScaleWidth(dimensions, standardWidth) {
  let scaledWidth = standardWidth;
  if (dimensions.width < defaultWindowWidth) {
    scaledWidth = Math.floor(
      (standardWidth * dimensions.width) / defaultWindowWidth,
    );
  }
  return scaledWidth;
}

function getDimensions(state) {
  if (state && state.dimensionsWidth && state.dimensionHeight) {
    return {width: state.dimensionsWidth, height: state.dimensionHeight};
  }
  return Dimensions.get('window');
}

export function scaleHW(standardHeight, standardWidth, state) {
  const dimensions = getDimensions(state);
  return {
    height: doScaleHeight(dimensions, standardHeight),
    width: doScaleWidth(dimensions, standardWidth),
  };
}

export function scaleH(standardHeight, state) {
  const dimensions = getDimensions(state);
  return doScaleHeight(dimensions, standardHeight);
}

export function scaleW(standardWidth, state) {
  const dimensions = getDimensions(state);
  return doScaleWidth(dimensions, standardWidth);
}

export function scaleMin(standardDimension, state) {
  const dimensions = getDimensions(state);
  return Math.min(
    doScaleHeight(dimensions, standardDimension),
    doScaleWidth(dimensions, standardDimension),
  );
}

export function sizeMaxW(
  smallScreenWidth,
  bigScreenWidth,
  state,
  cutOver = defaultWindowWidth,
) {
  const dimensions = getDimensions(state);
  if (dimensions.width > cutOver) {
    return bigScreenWidth;
  }
  return smallScreenWidth;
}
