import React from 'react';
import {Svg, Circle} from 'react-native-svg';

export default function Target(props) {
  return (
    <Svg height="80" width="160" viewBox="-600 -300 1200 600">
      <Circle
        x="0"
        y="0"
        fill="#FFF"
        r="250"
        stroke="#CE1126"
        strokeWidth="100"
        fillOpacity=".5"
      />
      <Circle x="0" y="0" fill="#CE1126" r="100" />
    </Svg>
  );
}
