import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import AwesomeButton from 'react-native-really-awesome-button';
import LinearGradient from 'react-native-linear-gradient';
import Equation from './Equation';
import {registerChange, scaleHW, scaleMin} from './scaleUtils';
export default class NumberButton extends React.Component {
  componentDidMount() {
    registerChange(this);
  }

  componentWillUnmount() {
    registerChange(this, true);
  }

  gradientColours() {
    if (this.props.isSelected) {
      return ['#F8F9FA', '#3B824C', '#43944E'];
    }
    if (this.props.isSoftSelected) {
      return ['#FBFAF9', '#C66632', '#DF7C37'];
    }
    return ['#F7F7F7', '#2C6C9D', '#3A8CCD'];
  }

  render() {
    return (
      <View style={[styles.view, scaleHW(108, 135, this.state)]}>
        <AwesomeButton
          borderRadius={15}
          {...scaleHW(80, 135, this.state)}
          raiseLevel={3}
          backgroundDarker="#202020"
          springRelease={false}
          onPress={() => this.props.onPress()}>
          <LinearGradient
            width="100%"
            height="100%"
            style={styles.innerView}
            colors={this.gradientColours()}>
            <View style={styles.innerView}>
              <Text style={[styles.text, {fontSize: scaleMin(42, this.state)}]}>
                {this.props.value}
              </Text>
              <Equation equation={this.props.equation} />
            </View>
          </LinearGradient>
        </AwesomeButton>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    width: 135,
    height: 108,
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerView: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontFamily: 'Chewy-Regular',
    color: 'white',
    fontSize: 36,
    textAlign: 'center',
    // fontWeight: 'bold',
    textShadowOffset: {width: 1, height: 1},
    textShadowColor: 'black',
    textShadowRadius: 1,
  },
});
