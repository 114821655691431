// import {
//   setJSExceptionHandler,
//   getJSExceptionHandler,
// } from 'react-native-exception-handler';
import AsyncStorage from '@react-native-async-storage/async-storage';
import log from './log';

let exceptionValue;

async function setException(value) {
  await AsyncStorage.setItem('@storage_ExceptionCount', value);
}

async function checkException() {
  return await AsyncStorage.getItem('@storage_ExceptionCount');
}

// const currentHandler = getJSExceptionHandler();

// setJSExceptionHandler(async (error, isFatal) => {
//   log.error('Got Exception', error, isFatal);
//   if (exceptionValue) {
//     await setException('MULTIPLE');
//   } else {
//     await setException('FIRST');
//   }
//   currentHandler();
// }, true);

export async function init(callback) {
  exceptionValue = await checkException();
  log.info('set exception value', exceptionValue);
  if (exceptionValue) {
    await setException('NONE');
  }
  if (callback) {
    callback();
  }
}

export function lastException() {
  return exceptionValue;
}
