export default [
  {
    name: 'Lightning',
    symbol: '⚡',
    duration: 30,
    limitName: '⚡ Lightning',
  },
  {
    name: 'Normal',
    symbol: '⏱',
    duration: 300,
    limitName: '⏱ Normal or ⚡ Lightning',
  },
  {
    name: 'Timeless',
    symbol: '⌛',
    duration: undefined,
    limitName: 'Any Duration',
  },
];
