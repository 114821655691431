/*global history*/
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {StyleSheet, View, Button, Dimensions} from 'react-native';
import {
  startWalkthrough,
  endWalkthrough,
  startSolveGame,
  stashWalkthrough,
  stashPopWalkthrough,
} from '../../actions/PlayerActions';
import {refreshWalkthrough} from '../../actions/GameStateActions';
import WalkthroughBanner from '../../WalkthroughBanner';

let performedWalkthrough = false;

class StartWalkthrough extends React.Component {
  constructor(props) {
    super(props);
    this.refreshWalkthrough = this.refreshWalkthrough.bind(this);
    this.startWalkthrough = this.startWalkthrough.bind(this);
  }

  componentDidMount() {
    this.startWalkthrough();
    if (
      window.location &&
      (window.location.pathname.startsWith('/sg/') ||
        window.location.pathname.startsWith('/solveGame/'))
    ) {
      const index = window.location.pathname.indexOf('/', 1);
      const [target, ...numbers] = window.location.pathname
        .substr(index + 1)
        .split('_');
      const searchParams = new URLSearchParams(window.location.search);
      let hints;
      if (searchParams.has('hints')) {
        const hintStrings = searchParams.get('hints').split('_');
        hints = hintStrings.map((hintString) => {
          const [, value1, operator, value2] = hintString.match(
            /(\d+)([^\d])(\d+)/,
          );
          return {value1, operator, value2};
        });
      }
      let sampleResult;
      if (searchParams.has('sample')) {
        sampleResult = searchParams.get('sample');
      }
      this.props.startSolveGame(target, numbers, hints, sampleResult);
      history.replaceState(null, null, '/game');
    }
    window.addEventListener('popstate', this.startWalkthrough);
    Dimensions.addEventListener('change', this.refreshWalkthrough);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.startWalkthrough);
    Dimensions.removeEventListener('change', this.refreshWalkthrough);
  }

  startWalkthrough() {
    if (
      window.location &&
      (window.location.pathname.startsWith('/sg/') ||
        window.location.pathname === '/game')
    ) {
      this.props.stashPopWalkthrough();
      if (!performedWalkthrough) {
        performedWalkthrough = true;
        this.props.startWalkthrough();
      }
    } else {
      this.props.stashWalkthrough();
    }
  }

  refreshWalkthrough() {
    this.props.refreshWalkthrough();
  }

  render() {
    if (this.props.isSolveGame || !this.props.isWalkthrough) {
      return this.props.children;
    }
    return (
      <View style={styles.page}>
        <View style={styles.pane}>{this.props.children}</View>
        <View style={styles.nav}>
          <Button
            title="Cancel Demo"
            onPress={() => this.props.endWalkthrough()}
          />
        </View>
        <WalkthroughBanner />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
  },
  pane: {
    width: '100%',
    height: '93%',
    flex: 9.3,
  },
  nav: {
    width: '100%',
    height: '7%',
    flex: 0.7,
  },
});

const mapStateToProps = (state) => {
  return {
    isWalkthrough: state.player.isWalkthrough,
    isSolveGame: state.player.isSolveGame,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startWalkthrough,
      endWalkthrough,
      refreshWalkthrough,
      stashWalkthrough,
      stashPopWalkthrough,
      startSolveGame,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(StartWalkthrough);
