import React from 'react';
import {StyleSheet, Animated, View, Text} from 'react-native';

export default class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.animateValue = new Animated.Value(0);
  }

  animate() {
    this.animateValue.setValue(0);
    Animated.loop(
      Animated.timing(this.animateValue, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: true,
      }),
    ).start();
  }

  componentDidMount() {
    this.animate();
  }

  renderDot(offset) {
    const fadeValues = [0, 1, 1, 0];
    fadeValues.push(...fadeValues.splice(0, offset));
    const scaleValues = [0.8, 1.05, 0.98, 1];
    scaleValues.push(...scaleValues.splice(0, offset));
    const fade = this.animateValue.interpolate({
      inputRange: [0, 1, 2, 3],
      outputRange: fadeValues,
    });
    const scale = this.animateValue.interpolate({
      inputRange: [0, 1, 2, 3],
      outputRange: scaleValues,
    });

    return (
      <Animated.View key={offset} style={{opacity: fade, transform: [{scale}]}}>
        <Text style={styles.text}>.</Text>
      </Animated.View>
    );
  }
  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>Loading</Text>
        {[2, 1, 0].map((offset) => this.renderDot(offset))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: 'row',
    height: 80,
  },
  text: {
    fontFamily: 'Chewy-Regular',
    color: '#ffd300',
    fontSize: 40,
    // fontWeight: 'bold',
    // height: '100%',
    textShadowOffset: {width: 2, height: 2},
    textShadowColor: 'black',
    textShadowRadius: 1,
    paddingHorizontal: 2,
  },
});
