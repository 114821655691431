import {play} from '../sounds';

const applySelectScreen = (screen, transitioningId) => ({
  type: 'SCREEN_SELECTED',
  payload: {screen, transitioningId},
});

const screenAnimationComplete = (transitioningId) => ({
  type: 'SCREEN_ANIMATION_COMPLETE',
  payload: {transitioningId},
});

let lastTransitioningId = 0;
export const selectScreen = (screen) => (dispatch, getState) => {
  const beforeState = getState();
  const transitioningId = ++lastTransitioningId;
  dispatch(applySelectScreen(screen, transitioningId));
  const afterState = getState();
  if (beforeState.nav.screen !== afterState.nav.screen) {
    play('SCREEN_CHANGE');
  }

  setTimeout(() => dispatch(screenAnimationComplete(transitioningId)), 1000);
};
