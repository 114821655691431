export function itemDetails(item) {
  if (item.availableTime) {
    let count = item.collected - item.used;
    const now = Date.now();
    let timeout = 0;
    item.availableTime.forEach((time) => {
      if (time >= now) {
        if (!timeout || time - now < timeout) {
          timeout = time - now;
        }
      } else {
        ++count;
      }
    });
    return {
      count,
      timeout,
      isTimed: true,
    };
  } else {
    return {
      count: item.collected - item.used,
      timeout: 0,
      isTimed: false,
    };
  }
}

export function itemEmpty(item) {
  return itemDetails(item).count <= 0;
}
